// utility functions implementing dotted-pathnamed object access
import { DataValue, MetaDataValue } from "@mirinae/classes/DataValue";

// follow dotted-pathname down through given obj to leaf bin and its prop, returning both
const parsePath = (obj, pathName, addLevels) => {
    const els = pathName.split(".").reverse();
    let bin = obj,
        prop = pathName;
    while (els.length > 1) {
        const nextEl = els.pop();
        if (typeof bin !== "object") {
            bin = {};
            break; // simulate not found
        }
        if (bin[nextEl] === undefined)
            if (addLevels)
                bin[nextEl] = {}; // auto add levels on set
            else return [{}, nextEl]; // this will cause getByPath to return undefined,  as wanted
        bin = bin[nextEl];
    }
    return [bin, els.pop()];
};

// set the leaf property in obj defined by dotted-path in pathname, or if leaf prop is '*', assign value to all props & descendants therefrom
export const setByPath = (obj, path, value) => {
    const [bin, prop] = parsePath(obj, path, true);
    if (bin[prop] !== value) bin[prop] = value;
};

export const getByPath = (obj, path) => {
    if (path) {
        if (Array.isArray(obj)) {
            // if obj is an array, assume it is a history stack & find first named element found working backwards through array (find most-recent entry)
            const o = obj
                .slice()
                .reverse()
                .find(o => getByPath(o, path));
            return o ? getByPath(o, path) : undefined;
        } else {
            const [bin, prop] = parsePath(obj, path); // should not add levels on get!! , true);
            return bin[prop];
        }
    }
};

export const getValueByPath = (obj, path, defaultValue) => {
    const value = getByPath(obj, path);
    return value ? DataValue.makeFrom(value) : defaultValue ? DataValue.makeFrom(defaultValue) : undefined;
};

// sets value in given session step's output flow data in the given node's namespace
export const setNodeOutput = (node, sessionStep, pathName, value) => {
    setByPath(sessionStep.output, `${node.nodeName || node.name}.${pathName}`, value);
};

// provides a way for a node to inject internally-generated values into the data-flow, as though upstream steps had generated these values as output
export const setNodeInput = (node, sessionStep, pathName, value, type) => {
    const sourcePathName = `${node.nodeName || node.name}.${pathName}`;
    setInputMapping(node, pathName, sourcePathName, type || value.type);
    setByPath(sessionStep.history[sessionStep.history.length - 1], sourcePathName, value);
};

// sets step's metadata
export const setStepMetadata = (sessionStep, value) => {
    setByPath(sessionStep.output, "metadata", new MetaDataValue(value));
};

export const getDataSourcePathName = (node, pathName) => {
    let sourcePathName = getInputMapping(node, pathName);
    if (!sourcePathName) {
        // allow deeper pathNames in getNodeInput calls, look for map the canonical input pathName prefix
        const path = pathName.split(".").reverse();
        let sourceName = path.pop();
        while (!getInputMapping(node, sourceName) && path.length > 0) {
            sourceName += "." + path.pop();
        }
        const prefix = getInputMapping(node, sourceName);
        sourcePathName = prefix ? prefix + (path.length > 0 ? "." + path.join(".") : "") : undefined;
    }
    if (sourcePathName) {
        sourcePathName = sourcePathName.replace("}", "");
    }
    return sourcePathName;
};

export const getDataSourceType = (node, pathName) => {
    let sourceType = getInputMappingType(node, pathName);
    if (!sourceType) {
        // allow deeper pathNames in getNodeInput calls, look for map the canonical input pathName prefix
        const path = pathName.split(".").reverse();
        let sourceName = path.pop();
        while (!getInputMapping(node, sourceName) && path.length > 0) {
            sourceName += "." + path.pop();
        }
        sourceType = getInputMappingType(node, sourceName);
    }
    return sourceType;
};

export const getDataSourcePathNameAndType = (node, pathName) => [getDataSourcePathName(node, pathName), getDataSourceType(node, pathName)];

export const setInputMapping = (node, inputPathName, sourcePathName, sourceType) => {
    const index = node.inputMap?.findIndex(inp => inp[0] === inputPathName);
    if (index >= 0) {
        node.inputMap[index] = [inputPathName, sourcePathName, sourceType];
    } else {
        node.inputMap.push([inputPathName, sourcePathName, sourceType]);
    }
};

export const deleteInputMapping = (node, inputPathName) => {
    const index = node.inputMap?.findIndex(inp => inp[0] === inputPathName);
    if (index >= 0) {
        node.inputMap.splice(index, 1);
    }
};

export const getInputMapping = (node, inputPathName) => {
    const inpMap = node.inputMap?.find(inp => inp[0] === inputPathName);
    return inpMap ? inpMap[1] : undefined;
};

const getInputMappingType = (node, inputPathName) => {
    const inpMap = node.inputMap?.find(inp => inp[0] === inputPathName);
    return inpMap ? inpMap[2] : undefined;
};

export const getNodeInput = (node, sessionStep, pathName, defaultValue) => {
    const sourceName = getDataSourcePathName(node, pathName);
    const value = getByPath(sessionStep.history, sourceName);
    return value ? DataValue.makeFrom(value) : defaultValue ? DataValue.makeFrom(defaultValue) : undefined;
};

export const requireNodeInput = (node, sessionStep, pathName) => {
    const sourceName = getDataSourcePathName(node, pathName);
    const data = getByPath(sessionStep.history, sourceName);
    if (!data || data.value === undefined) throw new Error(`Required node input ${pathName} is missing`);
    return DataValue.makeFrom(data);
};

export const getNodeInputHistory = (node, sessionStep, pathName) => {
    const sourceName = getDataSourcePathName(node, pathName);
    return sessionStep.history
        .map(h => [h, getByPath(h, sourceName)])
        .filter(([h, v]) => v)
        .map(([h, v]) => [h, DataValue.makeFrom(v)]);
};

export const getNewNodeInputs = (node, sessionStep, pathName) => {
    const sourceName = getDataSourcePathName(node, pathName);
    const newValues = [];
    const h = sessionStep.history;
    for (let i = h.length - 1; i >= 0 && h.source.nodeID !== node.node; i -= 1) {
        const value = getByPath(h[i], sourceName);
        if (value) newValues.push(DataValue.makeFrom(value));
    }
    return newValues;
};

export const getMergedNewNodeInputs = (node, sessionStep, pathName) => {
    const sourceName = getDataSourcePathName(node, pathName);
    let mergedValue = undefined;
    const h = sessionStep.history;
    // merge values found in history of given sourceName up to most-recent running of this node (thus giving "new" values this loop)
    // note values found must implement the merge() function
    for (let i = h.length - 1; i >= 0 && h[i].source.nodeID !== node.nodeID; i -= 1) {
        const value = getByPath(h[i], sourceName);
        mergedValue = value ? (mergedValue ? mergedValue.merge(DataValue.makeFrom(value)) : DataValue.makeFrom(value)) : mergedValue;
    }
    return mergedValue;
};

export const getNodeInputType = (node, pathName) => {
    return getDataSourceType(node, pathName);
};

export const resetNodeInput = (node, sessionStep, pathName, value) => {
    // allow input sources to be reset to a give state, useful for one-time use data inputs like prompt buttons
    // if the sourcing node runs again in the process flow, its run's value will be instated
    const sourcePathName = getDataSourcePathName(node, pathName);
    // hey?? reset all history instances???  yes, for now, otherwise won't fully reset
    sessionStep.history.forEach(h => {
        if (getByPath(h, sourcePathName)) {
            setByPath(h, sourcePathName, value);
        }
    });
};

// the following fns manage access to node state data, used to maintain state across multiple runs of a node
//   for now, just in a reserved namespace in the history trace
export const getNodeStatePathName = (node, pathName) => {
    return `${node.nodeName || node.name}.state.${pathName}`;
};

export const getNodeState = (node, sessionStep, pathName) => {
    const statePathName = getNodeStatePathName(node, pathName);
    return getByPath(sessionStep.history, statePathName);
};

export const setNodeState = (node, sessionStep, pathName, value) => {
    const statePathName = getNodeStatePathName(node, pathName);
    return setByPath(sessionStep.history, statePathName, value);
};

// same for loopState, making this a distinct top-level state subspace so controlling & monitoring nodes can introspect easily
// the entire node's loopState is accessed: loopState.<nodeName>.<node's-subspace>
export const getLoopStatePathName = node => {
    return `loopState.${node.nodeName || node.name}`;
};

export const getLoopState = (node, sessionStep) => {
    const statePathName = getLoopStatePathName(node);
    return getByPath(sessionStep.history, statePathName);
};

export const setLoopState = (node, sessionStep, value) => {
    const statePathName = getLoopStatePathName(node);
    return setByPath(sessionStep.output, statePathName, value);
};

// return those parameters that have been wired to data sources in the given node
export const getWiredParameters = (node, parameters) => {
    const wiredParameters = {};
    node.inputs.forEach(ip => {
        const parameter = getByPath(parameters, ip.pathName);
        if (ip.edgeID && parameter) {
            wiredParameters[ip.pathName] = parameter;
        }
    });
    return wiredParameters;
};

export const nodesInEdgeFlow = (flowGraph, startingEdgeID) => {
    // returns all nodes in the flowgraph that may be visited by following the given edge, including implied pure-data input nodes
    const startingNodeID = flowGraph.edges[startingEdgeID].sourceNodeID;
    const processNodeIDs = new Set();
    const dataNodeIDs = new Set();
    const followEdge = edgeID => {
        const edge = flowGraph.edges[edgeID];
        if (edge.type === "process-flow") {
            // first follow target flow if process-flow edge
            const targetNode = flowGraph.nodes[edge.targetNodeID];
            if (!processNodeIDs.has(targetNode.nodeID)) {
                processNodeIDs.add(targetNode.nodeID);
                for (const exit of targetNode.exits) {
                    if (exit.edgeID && exit.edgeID !== startingEdgeID) {
                        followEdge(exit.edgeID);
                    }
                }
            }
        }
        // then recurse back up inputs
        const sourceNode = flowGraph.nodes[edge.sourceNodeID];
        if (!dataNodeIDs.has(sourceNode.nodeID)) {
            dataNodeIDs.add(sourceNode.nodeID);
            for (const input of sourceNode.inputs) {
                if (input.edgeID && input.edgeID !== startingEdgeID) {
                    followEdge(input.edgeID);
                }
            }
        }
    };
    followEdge(startingEdgeID);
    return Array.from(new Set([...processNodeIDs, ...dataNodeIDs])).map(id => flowGraph.nodes[id]);
};

export const getParameter = (parameters, path, defaultValue) => {
    const parameter = getByPath(parameters, path);
    return parameter ? DataValue.makeFrom(parameter) : defaultValue ? DataValue.makeFrom(defaultValue) : undefined;
};

export const getEmbeddings = (node, sessionStep, notes) => {
    const selectedEmbeddings = getNodeInput(node, sessionStep, "embeddings");
    const embeddingSet = getNodeInput(node, sessionStep, "embeddingSet.id");

    if (!selectedEmbeddings && !embeddingSet) {
        return null;
    }
    const selection = selectedEmbeddings?.embeddingSetIDList || embeddingSet.embeddingSetIDList;
    return {
        sourceType: selectedEmbeddings ? "selection" : "embedding-set",
        notes: notes,
        sessionStepID: sessionStep._id.toString(),
        selection,
    };
};

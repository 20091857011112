// async http requests
/*
 *    Copyright © 2020, Mirinae Corp. and John Wainwright
 */

import axios from "axios";

export const defaultOptions = {
    method: "POST",
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
};

export const httpAPI = function (host, path, callOptions) {
    const options = { ...defaultOptions, ...callOptions };
    return new Promise((resolve, reject) => {
        axios(host + path, options)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const localPaths = {
    home: "",
    admin: "admin",
    catalog: "catalog",
    flowGraphEditor: "editor",
    flowGraphRunner: "runner",
    runHistories: "histories",
    editor: "editor",
    runner: "runner",
    browser: "browser",
    histories: "histories",
    oldUI: "oldui",
    simulator: "simulator",
    chatbot: "chatbot",
};

// various UI and formatting utility functions

export const getLocalISODateTime = () => {
    const now = new Date();
    const tzOffset = now.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localISOTime = new Date(now - tzOffset).toISOString().slice(0, -1);
    return localISOTime;
};

export const getLocalISODateAndTime = () => {
    const [d, t] = getLocalISODateTime().split("T");
    return [d, t.replace(/\..*?$/, "")];
};

export const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const localDate = dateString => {
    // mild hack
    const d = new Date(dateString);
    const local = new Date(d.valueOf() - d.getTimezoneOffset() * 60 * 1000).toISOString().replace("T", " ");
    return local.substring(0, local.length - 8);
};

export const utcDate = dateString => {
    const d = new Date(dateString);
    const local = new Date(d.valueOf()).toISOString().replace("T", " ");
    return local.substring(0, local.length - 8);
};

// split an array into subarrays based on a separating-element predicate
export const splitArrayByCondition = (arr, predicate) => {
    const result = [];
    let currentSubArray = [];
    arr.forEach(item => {
        if (predicate(item)) {
            if (currentSubArray.length > 0) {
                result.push(currentSubArray);
                currentSubArray = [];
            }
        } else {
            currentSubArray.push(item);
        }
    });
    if (currentSubArray.length > 0) {
        result.push(currentSubArray);
    }
    return result;
};

export const getRandomPastelColor = () => {
    const h = Math.floor(Math.random() * 361);
    const s = 100; // Math.floor(Math.random() * 20) + 50; // 25 to 50
    const l = Math.floor(Math.random() * 10) + 88; // 85 to 95
    const rgb = hslToRgb(h, s, l);

    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.35)`;
};

function hslToRgb(h, s, l) {
    // Convert s and l from percentage to [0,1]
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s; // Chroma
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;

    let rPrime, gPrime, bPrime;

    if (h >= 0 && h < 60) {
        rPrime = c;
        gPrime = x;
        bPrime = 0;
    } else if (h >= 60 && h < 120) {
        rPrime = x;
        gPrime = c;
        bPrime = 0;
    } else if (h >= 120 && h < 180) {
        rPrime = 0;
        gPrime = c;
        bPrime = x;
    } else if (h >= 180 && h < 240) {
        rPrime = 0;
        gPrime = x;
        bPrime = c;
    } else if (h >= 240 && h < 300) {
        rPrime = x;
        gPrime = 0;
        bPrime = c;
    } else {
        rPrime = c;
        gPrime = 0;
        bPrime = x;
    }

    // Convert to [0,255] and round
    const r = Math.round((rPrime + m) * 255);
    const g = Math.round((gPrime + m) * 255);
    const b = Math.round((bPrime + m) * 255);

    return { r, g, b };
}

// holds a class hierachy whose instances represent nodes the directory stores so they can provide polymorphic directory operations
import { immerable } from "immer";
import _ from "lodash";
import { useFlowGraphStore } from "@hyperflow/modules/stores/flowgraph";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/defines/paths";
import flowgraphEngine from "@hyperflow/modules/engines/flowgraph";
import { localDate } from "@mirinae/shared/modules/utils/formatters";
import { useViewerStore } from "@hyperflow/modules/stores/viewer";
import { useInspectorStore } from "@hyperflow/modules/stores/inspector";
import { flowgraphStoreUnSubscribeAll } from "@hyperflow/modules/stores/flowgraph";
import { useAppStore } from "@hyperflow/modules/stores/app";

// since instances of these classes will be managed in a zustand/immer store, we need to mark the classes as 'immerable' and use immer produce() to
// wrap any instance-internal state changes that should be tracked by the directory stores.    See this reference: https://immerjs.github.io/immer/complex-objects

// marking the class as immerable will cause immer to deconstruct an instance of the class to a plain JS object within the draft proxy
// and re-create an instance when the draft becomes the new state, tracking changes as usual in the store methods that use immer produce().
// The only complications arise if methods on the class modify its state, whence you should wrap that in a produce() call, just as in a store methods,
// or if you have getter methods in the class, whence you must have corresponding setters to allow the re-instancing to be implemented for that
// property by immer.

export class DirectoryNode {
    [immerable] = true;

    constructor() {}

    static classFromType(type) {
        return {
            segmentationSet: SegmentationSet,
            importSet: ImportSet,
            "built-in-node-class": FlowGraphNode,
            "built-in-flow-graph": FlowGraph,
            "flow-graph": FlowGraph,
            session: Session,
            embeddingSet: EmbeddingSet,
            vectorDB: VectorDB,
            "built-in-adapter": Adapter,
            adapter: Adapter,
        }[type];
    }

    static makeFrom(src) {
        if (src instanceof DirectoryNode) return src;
        const cls = this.classFromType(src.type);
        if (cls) {
            const newInstance = new cls();
            Object.assign(newInstance, src);
            return newInstance;
        } else {
            console.log("argh, missing directory node type", src);
        }
    }

    select() {
        // specialize, when selected in the main directory
    }

    directorySelectPostProcessing(paramUI) {
        // specialize, when node is selected in DirectorySelect parameter, given paramUI contrrolling the selection
    }

    displayValue() {
        return this.displayValue;
    }
}

export class FlowGraph extends DirectoryNode {
    async select() {
        // selecting a flow-graph loads it into the graph-viewer (if not busy) and details into the inspector pane
        const {
            flow: { runState },
            methods: { checkFlowGraph, setFlowGraph },
        } = useFlowGraphStore.getState();
        const {
            methods: { setEditingEnabled, setFocusedNode },
        } = useViewerStore.getState();
        const {
            methods: { setViewInfo },
        } = useInspectorStore.getState();
        const { appMode } = useAppStore.getState();

        if (["idle", "loaded", "failed", "finished"].includes(runState)) {
            try {
                flowgraphStoreUnSubscribeAll();
                setFocusedNode(null);
                setFlowGraph(null);
                let {
                    data: { flowGraph },
                } = await httpAPI("", `${apiPaths.getFlowGraph}/${this.objectID}`, { method: "GET" });
                checkFlowGraph(flowGraph);
                if (flowGraph.type === "built-in-flow-graph") {
                    // clone built-in to allow local edits, I think(??)
                    flowGraph = await flowgraphEngine.selectFlowGraphTemplate(flowGraph);
                } else {
                    setFlowGraph(flowGraph);
                }
                setEditingEnabled(flowGraph, appMode);
                setViewInfo([
                    { label: "Description", value: flowGraph.description },
                    { label: "Last updated", value: localDate(flowGraph.updated || flowGraph.created) },
                    { label: "_id", value: this.objectID.toString() },
                ]); // hey, this should be data-driven in the flow-graph spec with some useful defaults; provide base classes for nodes, flow-graphs, etc.
            } catch (e) {
                setViewInfo([{ label: "Error selecting flow-graph", value: e.toString() }]);
                console.log("Error selecting flow-graph");
            }
        }
    }
}

export class FlowGraphNode extends DirectoryNode {}

export class ImportSet extends DirectoryNode {}

export class SegmentationSet extends DirectoryNode {}

export class Session extends DirectoryNode {
    async select() {
        const {
            methods: { loadSession },
        } = useFlowGraphStore.getState();
        const {
            data: { session, steps },
        } = await httpAPI("", `${apiPaths.getSession}/${this.objectID}`, { method: "GET" });
        loadSession(
            session,
            _.chain(steps)
                .sortBy(step => step.index)
                .map(step => {
                    // step.parameterUI = [];
                    return step;
                })
                .value()
        );
    }
}

export class EmbeddingSet extends DirectoryNode {}

export class VectorDB extends DirectoryNode {
    async directorySelectPostProcessing(selection, parameter, paramUI) {
        // augment the directory selection object with the service related to this vector DB
        const {
            methods: { setParameter, findService },
        } = useFlowGraphStore.getState();
        const {
            data: { vectorDB },
        } = await httpAPI("", apiPaths.getVectorDB, { data: { id: this.objectID } });

        // allow the parameterSpec to override the serviceType required for the delivered service
        //   eg, rag.vectorDB.select instead of a selected VDB's rag.vectorDB.create, to ask for the 'select' service adapter parameters, not the creator's.
        const serviceType = paramUI.usesServiceType;
        const { serviceFamily } = vectorDB.service;
        selection.relatedService = findService({ serviceType, serviceFamily });
        selection.relatedObject = vectorDB;
    }
}

export class KnowledgeDB extends DirectoryNode {
    async directorySelectPostProcessing(selection, parameter, paramUI) {
        // augment the directory selection object with the service related to this vector DB
        const {
            methods: { setParameter, findService },
        } = useFlowGraphStore.getState();
        const {
            data: { knowledgeDB },
        } = await httpAPI("", apiPaths.getKnowledgeDB, { data: { id: this.objectID } });

        const serviceType = paramUI.usesServiceType;
        const { serviceFamily } = knowledgeDB.service;
        selection.relatedService = findService({ serviceType, serviceFamily });
        selection.relatedObject = knowledgeDB;
    }
}

export class Adapter extends DirectoryNode {}

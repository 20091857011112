import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";
import { current, produce, isDraft } from "immer";
import cloneDeep from "lodash.clonedeep";
import merge from "lodash.merge";
import objectDeepCompare from "object-deep-compare";
const deepCompare = (a, b) => a && b && objectDeepCompare.CompareValuesWithConflicts(a, b).length === 0;

import { getByPath, setByPath, setInputMapping, deleteInputMapping, getParameter } from "@mirinae/shared/modules/utils/pathUtils";
import { ColorValue, DataValue } from "@mirinae/classes/DataValue";
import flowgraphEngine from "@mirinae/hyperflow/modules/engines/flowgraph";
import { getRandomPastelColor } from "@mirinae/shared/modules/utils/formatters";

// see immer support for class instances - https://immerjs.github.io/immer/complex-objects/

// parameter management: in the following, there are sets of three structures related to parameter acquisition and storage, one for the
// overall run setup, acquired before the run starts, and sets for each of the session steps as they run (corresponding to a flow-graph node
// evaluation).  The parameterSpecs in the node or service definition defines the UI required to acquire the paramters, the parameterUI is
// the runtime structure built to show the UI & interact with the user, and the parameter values themselves.
// The specs & values are divided into namespaces prefixed by the flow
// or node name (which is expected to be unique across the app).  At runtime, the setup & step parameters are merged into a single parameters
// structure supplied to the currently-executing node's code.

const initStep = {
    controllingParameter: null, // most-recently declared dependency-graph controlling parameter
    activeControllingParams: new Set(), // table of active controlling parameters
    parameterUI: [], // { type: 'select|...', pathName: '...', done: true|false ],
    runButtonTrigger: 0,
    parameters: {}, // stores current parameters for the step's node (effectively overriding those in the flow.flowGraph)
    nodeID: 0,
    index: 0,
    stepContext: {},
    generation: {
        text: "",
    },
    displays: [
        // any node step can add notices or values to be displayed when the step completes, the static representations of the step's effects
        // { promptText: "What is the airspeed velocity of an unladen swallow?" },
        // { generatedText: "What? Oh, I don't know!" },
        // { error: { service: '...', message: '...' } },
    ],
    configurationPreview: null,
    metadata: null,
    exception: null,
    userMessage: null,
    nodeReady: {}, // set when node ready to execute
    summary: null,
    showParameterHistory: false,
    paramUILoading: false,
    gatheringParameters: false,
    hadParameterInput: false,
    expanded: true, // expand/contract state in historical run logs
};

const initStore = {
    flow: {
        // active flow (if flow.flowGraph non-null)
        flowGraph: null, // a clone of the flowGraph defn containing the running flowGraph instance, including the current run-state settings for parameters & parameterSpecs
        runState: "idle", // 'loaded', 'configuring', 'ready', 'running', 'configuring-resetting', 'running-resetting', 'single-step',  'paused', 'failed', 'finished'
        nextStep: {},
        steps: [
            {
                ...initStep, // pre-run configure is step 0
            },
        ],
        currentNodeID: 0, // currently running node
        currentStepIndex: 0, // indexes currently running entry in the steps array below, step 0 is the pre-run configure step
        sessionGuid: "",
        sessionID: "",
        sessionStepID: "", // a single store stepID might make concurrent flow-branch execution problematic
        busy: false,
        busyMessage: "",
        busyStatus: "",
        busyDebounce: null,
        highlightedEdge: {},
        undoStack: [], // stack of flow-graph copies at each edit; hey!! implement stack-depth max
        undoIndex: 0,
        pastedNodeIDs: [],
        runOrdinal: 0,
    },

    // available hyperflow tools cache, such as flowgraphs, templates, adapters, etc. from built-ins or plugins, not sure they should be cached here
    tools: {
        proformaFlowGraph: {},
        nodeClasses: [],
        adapters: [],
        initialized: false,
    },

    clipboard: { nodes: [], edges: [] },
};

export const useFlowGraphStore = create(
    subscribeWithSelector((set, get) => ({
        ...cloneDeep(initStore),

        methods: {
            loadSession: (session, steps) => {
                set(
                    produce(state => {
                        const lastStep = steps[steps.length - 1];
                        state.flow = {
                            flowGraph: session.flowGraph,
                            runState: "finished",
                            steps: [cloneDeep(initStep), ...steps], // steps start at index 1, index 0 reserved for node configuration modes prior to run start
                            currentNodeID: 0,
                            currentStepIndex: session.nextStepIndex,
                            sessionGuid: session.guid,
                            sessionStepID: lastStep._id.toString(),
                            busy: false,
                            busyMessage: "",
                            busyStatus: "",
                            busyDebounce: null,
                            highlightedEdge: {},
                        };
                    })
                );
            },

            setTools: tools =>
                set(
                    produce(state => {
                        state.tools = { ...tools, initialized: true };
                    })
                ),

            setFlowRunState: runState =>
                set(
                    produce(state => {
                        state.flow.runState = runState;
                    })
                ),

            setRunOrdinal: ordinal =>
                set(
                    produce(state => {
                        state.flow.runOrdinal = ordinal;
                    })
                ),

            setStartNodeID: nodeID => {
                set(
                    produce(state => {
                        state.flow.flowGraph.startNodeID = nodeID;
                    })
                );
                return get().flow.flowGraph;
            },

            setStepContext: stepContext =>
                set(
                    produce(state => {
                        const currentStep = state.flow.steps[state.flow.currentStepIndex];
                        currentStep.stepContext = stepContext;
                    })
                ),

            triggerRunButton: () =>
                set(
                    produce(state => {
                        const currentStep = state.flow.steps[state.flow.currentStepIndex];
                        currentStep.runButtonTrigger += 1;
                    })
                ),

            toggleStepExpansion: stepIndex =>
                set(
                    produce(state => {
                        const step = state.flow.steps[stepIndex];
                        step.expanded = !step.expanded;
                    })
                ),

            runStarting: (flowGraph, options = { singleStep: false }) =>
                set(
                    produce(state => {
                        flowgraphStoreUnSubscribeAll();
                        const newStep = cloneDeep({
                            ...initStep,
                            index: 1,
                            nodeID: flowGraph.startNodeID,
                            node: flowGraph.nodes[flowGraph.startNodeID],
                            nodeDisplayName: flowGraph.nodes[flowGraph.startNodeID].displayName,
                            parameters: {
                                ...(flowGraph.parameters || {}),
                                ...(flowGraph.nodes[flowGraph.startNodeID].parameters || {}),
                                ...state.flow.steps[0].parameters, // add in any configure-phase parameters received
                            },
                        });
                        state.flow.currentStepIndex = 1;
                        state.flow.currentNodeID = flowGraph.startNodeID;
                        state.flow.steps = [cloneDeep(initStep), newStep];
                        state.flow.runState = options.singleStep ? "single-step" : "running";
                    })
                ),

            setNextStep: (message, stepContext) =>
                set(
                    produce(state => {
                        state.flow.nextStep = { message, stepContext };
                    })
                ),

            initUndo: () =>
                set(
                    produce(state => {
                        if (
                            state.flow.flowGraph &&
                            (state.flow.undoIndex === 0 || state.flow.undoStack[0].guid !== state.flow.flowGraph.guid)
                        ) {
                            state.flow.undoStack = [cloneDeep(state.flow.flowGraph)];
                            state.flow.undoIndex = 1;
                            const debug = current(state.flow);
                            // console.log("initUndo out", state.flow.undoIndex, state.flow.undoStack);
                        }
                    })
                ),

            saveForUndo: () => {
                if (get().flow.undoIndex === 0) {
                    get().methods.initUndo();
                } else {
                    set(
                        produce(state => {
                            if (!deepCompare(state.flow.flowGraph, state.flow.undoStack[state.flow.undoIndex - 1])) {
                                // console.log("saveForUndo in", state.flow.undoIndex, current(state.flow.undoStack));
                                // console.log("    ", state.flow.nodes?[16]?.position);
                                state.flow.undoStack.splice(state.flow.undoIndex);
                                state.flow.undoStack.push(cloneDeep(state.flow.flowGraph));
                                state.flow.undoIndex = state.flow.undoStack.length;
                                const debug = current(state.flow);
                                // console.log("saveForUndo out", state.flow.undoIndex, current(state.flow.undoStack));
                            }
                        })
                    );
                }
                return get().flow.flowGraph;
            },

            undo: () => {
                let undoneFlowGraph;
                set(
                    produce(state => {
                        // console.log("undo in", state.flow.undoIndex, current(state.flow.undoStack));
                        // console.log("    ", state.flow.nodes?[16].?position);
                        const debug = current(state.flow);
                        if (state.flow.undoIndex > 1) {
                            undoneFlowGraph = state.flow.undoStack[state.flow.undoIndex - 2];
                            const debug2 = current(undoneFlowGraph);
                            state.flow.undoIndex -= 1;
                            state.flow.flowGraph = undoneFlowGraph;
                            // console.log("undo out", state.flow.undoIndex, current(state.flow.undoStack));
                        }
                        // console.log("    ", state.flow.nodes?[16].?position);
                    })
                );
                return undoneFlowGraph && get().flow.flowGraph;
            },

            redo: () => {
                let redoneFlowGraph;
                set(
                    produce(state => {
                        // console.log("redo in", state.flow.undoIndex, current(state.flow.undoStack));
                        if (state.flow.undoIndex < state.flow.undoStack.length) {
                            redoneFlowGraph = state.flow.undoStack[state.flow.undoIndex];
                            state.flow.undoIndex += 1;
                            state.flow.flowGraph = redoneFlowGraph;
                        }
                        // console.log("redo out", state.flow.undoIndex, redoneFlowGraph, current(state.flow.undoStack));
                    })
                );
                return redoneFlowGraph && get().flow.flowGraph;
            },

            copy: nodeIDs => {
                set(
                    produce(state => {
                        const {
                            flow: { flowGraph },
                        } = state;
                        const nodes = cloneDeep(nodeIDs.map(id => flowGraph.nodes[id]));
                        // grab edges that have src & tgt within the copied nodes
                        const edges = cloneDeep(
                            Object.values(flowGraph.edges).filter(
                                edge => nodeIDs.includes(edge.sourceNodeID) && nodeIDs.includes(edge.targetNodeID)
                            )
                        );
                        // null out node data & process flow ports edges not in the copied edges
                        const edgeSet = edges.reduce((m, e) => {
                            m.add(e.edgeID);
                            return m;
                        }, new Set());
                        nodes.forEach(n => {
                            n.entries.forEach(en => {
                                en.edgeID = edgeSet.has(en.edgeID) ? en.edgeID : null;
                            });
                            n.exits.forEach(ex => {
                                ex.edgeID = edgeSet.has(ex.edgeID) ? ex.edgeID : null;
                            });
                            n.inputs.forEach(inp => {
                                inp.edgeID = edgeSet.has(inp.edgeID) ? inp.edgeID : null;
                            });
                            n.outputs.forEach(op => {
                                op.edgeIDs = op.edgeIDs.filter(id => edgeSet.has(id));
                            });
                        });
                        state.clipboard = { type: "nodes-and-edges", source: flowGraph._id, nodes, edges };
                    })
                );
                const debug = get().clipboard;
                return get().clipboard;
            },

            paste: (position, optionalClipboard) => {
                let pastedNodes = [];
                set(
                    produce(state => {
                        const {
                            flow: { flowGraph },
                        } = state;
                        const clipboard = optionalClipboard || state.clipboard;
                        // const debug = current(clipboard);
                        if (clipboard.type === "nodes-and-edges") {
                            // first build map for pasted node & edge IDs & origin pos of the selection
                            let minX = Infinity,
                                minY = Infinity;
                            const nodeIDMap = clipboard.nodes.reduce((m, n) => {
                                m[n.nodeID] = flowGraph.nextNodeID;
                                flowGraph.nextNodeID += 1;
                                minX = Math.min(minX, n.position.x);
                                minY = Math.min(minY, n.position.y);
                                return m;
                            }, {});
                            const edgeIDMap = clipboard.edges.reduce((m, e) => {
                                m[e.edgeID] = flowGraph.nextEdgeID;
                                flowGraph.nextEdgeID += 1;
                                return m;
                            }, {});

                            // paste in nodes, with top-left at given position
                            const nodeNameMap = {};
                            const pastedNodes = [];
                            for (const cbn of clipboard.nodes) {
                                const node = cloneDeep(cbn);
                                node.nodeID = nodeIDMap[node.nodeID];
                                node.position = { x: position.x + node.position.x - minX, y: position.y + node.position.y - minY };
                                // update port edgeID refs
                                node.entries.forEach(en => {
                                    en.edgeID = edgeIDMap[en.edgeID] || null;
                                });
                                node.exits.forEach(ex => {
                                    ex.edgeID = edgeIDMap[ex.edgeID] || null;
                                });
                                node.inputs.forEach(ip => {
                                    ip.edgeID = edgeIDMap[ip.edgeID] || null;
                                });
                                node.outputs.forEach(op => {
                                    op.edgeIDs = op.edgeIDs.map(id => edgeIDMap[id] || null);
                                });
                                // update unique nodeName & build old-to-new nodeName map
                                const highNumNodeName = Object.values(state.flow.flowGraph.nodes).reduce(
                                    (a, n) => (n.name === node.name ? (n.nodeName > a ? n.nodeName : a) : a),
                                    ""
                                );
                                const nextNum = highNumNodeName ? Number(highNumNodeName.substring(highNumNodeName.length - 2)) + 1 : 1;
                                const oldNodeName = node.nodeName; // remember old nodeName for later input & eddge sourcePathName mapping
                                node.nodeName = `${node.name}${nextNum.toString().padStart(2, "0")}`;
                                nodeNameMap[oldNodeName] = node.nodeName;
                                //
                                flowGraph.nodes[node.nodeID] = node;
                                pastedNodes.push(node);
                            }
                            state.flow.pastedNodeIDs = pastedNodes.map(n => n.nodeID);

                            // update node inputMaps now all have been assigned new nodeNames
                            const sourcePathNameMap = {};
                            for (const node of pastedNodes) {
                                node.inputMap = node.inputMap.map(([pathName, oldSourcePathName, type]) => {
                                    const splits = oldSourcePathName.split(".");
                                    const [oldNodeName, path] = [splits[0], splits.slice(1).join(".")];
                                    const newSourcePathName = `${nodeNameMap[oldNodeName]}.${path}`;
                                    sourcePathNameMap[oldSourcePathName] = newSourcePathName;
                                    return [pathName, newSourcePathName, type];
                                });
                            }

                            // paste in edges, remapping src & tgt
                            for (const cbe of clipboard.edges) {
                                const edge = cloneDeep(cbe);
                                edge.edgeID = edgeIDMap[edge.edgeID] || null;
                                edge.sourceNodeID = nodeIDMap[edge.sourceNodeID];
                                edge.targetNodeID = nodeIDMap[edge.targetNodeID];
                                // update sourcePathName
                                if (edge.sourcePathName) edge.sourcePathName = sourcePathNameMap[edge.sourcePathName];
                                flowGraph.edges[edge.edgeID] = edge;
                            }
                            // const debug = get().flow;
                        }
                    })
                );
                return [get().flow.pastedNodeIDs, get().flow.flowGraph];
            },

            updateStepNodeID: nodeID =>
                set(
                    produce(state => {
                        // called if a flow start does start with the first process node, but sourcing pure-data nodes are run first
                        const { flow } = state;
                        const step = state.flow.steps[flow.currentStepIndex];
                        if (nodeID !== step.nodeID) {
                            const node = flow.flowGraph.nodes[nodeID];
                            flow.currentNodeID = nodeID;
                            step.nodeID = nodeID;
                            step.node = node;
                            step.nodeDisplayName = node.displayName;
                            step.parameters = {
                                ...(node.parameters || {}),
                                ...flow.steps[0].parameters,
                            };
                        }
                    })
                ),

            completeSessionStep: () => {
                const { flow } = get();
                const step = flow.steps[flow.currentStepIndex];
                const { displays, parameterUI, exception, userMessage, metadata } = step;
                return {
                    displays,
                    parameterUI,
                    exception,
                    userMessage,
                    metadata,
                };
            },

            newSessionStep: (index, nodeID, nodeDisplayName, sessionStepID) =>
                set(
                    produce(state => {
                        console.log("newSessionStep", index, nodeID);
                        const node = state.flow.flowGraph.nodes[nodeID];
                        const newStep = cloneDeep({
                            ...initStep,
                            index,
                            nodeID,
                            node,
                            nodeDisplayName,
                            sessionStepID,
                            parameters: node.parameters,
                        });
                        state.flow.currentStepIndex = index;
                        state.flow.currentNodeID = nodeID;
                        state.flow.steps.push(newStep);
                    })
                ),

            setStepException: exception =>
                set(
                    produce(state => {
                        const { steps, currentStepIndex } = state.flow;
                        steps[currentStepIndex].exception = exception;
                        steps[currentStepIndex].paramUILoading = false;
                        state.flow.busy = false;
                    })
                ),

            setStepUserMessage: userMessage =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].userMessage = userMessage;
                    })
                ),

            instanceNodeClass: (objectID, position) => {
                // instance node-class given its objectID, wire into current flowGraph
                const state = get();
                const nodeClass = state.tools.nodeClasses.find(nc => nc._id.toString() === objectID);
                if (nodeClass) {
                    const newNode = cloneDeep(nodeClass); // hey, should be actual JS class instances ???
                    delete newNode._id;
                    newNode.created = Date.now();
                    newNode.nodeID = state.flow.flowGraph.nextNodeID;
                    if (position) newNode.position = { ...position };
                    console.log("newNode.position", newNode.position);
                    set(
                        produce(state => {
                            const highNumNodeName = Object.values(state.flow.flowGraph.nodes).reduce(
                                (a, n) => (n.name === newNode.name ? (n.nodeName > a ? n.nodeName : a) : a),
                                ""
                            );
                            const nextNum = highNumNodeName ? Number(highNumNodeName.substring(highNumNodeName.length - 2)) + 1 : 1;
                            newNode.nodeName = `${newNode.name}${nextNum.toString().padStart(2, "0")}`;
                            state.flow.flowGraph.nextNodeID += 1;
                            state.flow.flowGraph.nodes[newNode.nodeID] = newNode;
                        })
                    );
                    return get().flow.flowGraph;
                }
            },

            updateNodePosition: (nodeID, position) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes, edges } = flowGraph;
                        const node = nodes[nodeID];
                        node.position = position;
                        // check if any connected edges start going backwards
                        const exitEdgeIDs = node.exits.map(en => en.edgeID);
                        node.outputs.forEach(op => op.edgeIDs.forEach(eid => exitEdgeIDs.push(eid)));
                        for (const exitEdgeID of exitEdgeIDs)
                            if (exitEdgeID && exitEdgeID in edges) {
                                const edge = edges[exitEdgeID];
                                const shouldRecurse = nodes[edge.targetNodeID].position.x <= position.x;
                                if (edge.recursingEdge !== shouldRecurse) {
                                    edge.recursingEdge = shouldRecurse;
                                }
                                if (shouldRecurse) {
                                    console.log("updateNodePosition, exit edge nulling centerRatio");
                                    edge.centerRatio = null;
                                }
                            }
                        const entryEdgeIDs = node.inputs.map(op => op.edgeID).concat(node.entries.map(en => en.edgeID));
                        for (const entryEdgeID of entryEdgeIDs)
                            if (entryEdgeID && entryEdgeID in edges) {
                                const edge = edges[entryEdgeID];
                                const shouldRecurse = nodes[edge.sourceNodeID].position.x >= position.x;
                                if (edge.recursingEdge !== shouldRecurse) {
                                    edge.recursingEdge = shouldRecurse;
                                }
                                if (shouldRecurse) {
                                    console.log("updateNodePosition, entry edge nulling centerRatio");
                                    edge.centerRatio = null;
                                }
                            }
                    })
                );
                return get().flow.flowGraph;
            },

            updateNodeSize: (nodeID, size) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        const node = nodes[nodeID];
                        node.size = size;
                        if (node.name === "annotation" && !node.parameters?.backgroundColor) {
                            // mild hack to force in a background color to all annotations
                            setByPath(node.parameters, "backgroundColor", new ColorValue(getRandomPastelColor()));
                        }
                    })
                );
                return get().flow.flowGraph;
            },

            updateNodeBackgroundColor: (nodeID, color) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        const node = nodes[nodeID];
                        setByPath(node.parameters, "backgroundColor", new ColorValue(color));
                    })
                );
                return get().flow.flowGraph;
            },

            connectNodes: (sourceNodeID, sourceEdgeIndex, targetNodeID, options = {}) => {
                // process-flow connect a source exit & target entry + wire any unwired data flows we can find ends for in the current graph
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes, edges, nextEdgeID } = flowGraph;
                        const sourceNode = nodes[sourceNodeID];
                        const targetNode = nodes[targetNodeID];
                        // for now, simple node position check to see if edge is going backwards
                        const recursingEdge = sourceNode.position.x >= targetNode.position.x;
                        edges[nextEdgeID] = {
                            edgeID: nextEdgeID,
                            type: "process-flow",
                            recursingEdge,
                            sourceNodeID,
                            targetNodeID,
                        };
                        sourceNode.exits[sourceEdgeIndex].edgeID = nextEdgeID;
                        const emptyEntryIndex = targetNode.entries.findIndex(en => !en.edgeID);
                        const label = `Edge ${nextEdgeID} entry`;
                        if (emptyEntryIndex >= 0) {
                            targetNode.entries[emptyEntryIndex].edgeID = nextEdgeID;
                            targetNode.entries[emptyEntryIndex].label = label;
                        } else {
                            targetNode.entries.push({
                                edgeID: nextEdgeID,
                                label,
                            });
                        }
                        flowGraph.nextEdgeID += 1;
                    })
                );

                if (options.autoConnectDataFlows) {
                    // now check for unwired (implicit) data inputs in the target node and its exit subgraph of nodes following the entries tree & hooking them up if we can
                    const checkedNodes = new Set();
                    const checkTargetGraph = tgtNodeID => {
                        checkedNodes.add(tgtNodeID);
                        set(
                            produce(state => {
                                const flowGraph = state.flow.flowGraph;
                                const { nodes, edges } = flowGraph;
                                let { nextEdgeID } = flowGraph;
                                const targetNode = nodes[tgtNodeID];
                                const forDebug = current(flowGraph);

                                targetNode.inputs
                                    .filter(ip => !ip.edgeID)
                                    .forEach(ip => {
                                        const checkedSourceNodes = new Set();
                                        const findSource = entry => {
                                            const de = current(entry);
                                            if (entry.edgeID) {
                                                const srcNode = nodes[edges[entry.edgeID].sourceNodeID];
                                                if (checkedSourceNodes.has(srcNode.nodeID)) {
                                                    return false;
                                                }
                                                checkedSourceNodes.add(srcNode.nodeID);
                                                const dn = current(srcNode);
                                                const index = srcNode.outputs.findIndex(op => op.pathName === ip.pathName);
                                                if (index >= 0) {
                                                    // found! add data-flow edge
                                                    const recursingEdge = srcNode.position.x > targetNode.position.x;
                                                    const sourcePathName = `${srcNode.nodeName || srcNode.name}.${ip.pathName}`;
                                                    const sourceType = srcNode.outputs[index].type;
                                                    edges[nextEdgeID] = {
                                                        edgeID: nextEdgeID,
                                                        type: "data-flow",
                                                        recursingEdge,
                                                        sourceNodeID: srcNode.nodeID,
                                                        targetNodeID: targetNode.nodeID,
                                                        sourcePathName,
                                                        sourceType,
                                                    };
                                                    setInputMapping(targetNode, ip.pathName, sourcePathName, sourceType);
                                                    ip.edgeID = nextEdgeID;
                                                    srcNode.outputs[index].edgeIDs.push(nextEdgeID);
                                                    nextEdgeID += 1;
                                                    flowGraph.nextEdgeID = nextEdgeID;
                                                    return true;
                                                } else {
                                                    // recurse back along the entries subgrap looking for unwired inputs to satisfy
                                                    return (
                                                        targetNode.nodeID !== srcNode.nodeID &&
                                                        srcNode.entries.some(entry => findSource(entry))
                                                    );
                                                }
                                            }
                                        };
                                        targetNode.entries.some(entry => findSource(entry));
                                    });
                                const forDebug2 = current(flowGraph);
                            })
                        );

                        // recurse down the targets subgraph, then back along each subgraph's source subgraphs looking for input sources for each target node
                        const {
                            flow: {
                                flowGraph: { nodes, edges },
                            },
                        } = get();
                        const targetNode = nodes[tgtNodeID];
                        targetNode.exits
                            .filter(ex => ex.edgeID)
                            .forEach(exit => {
                                const { targetNodeID: tgtNodeID } = edges[exit.edgeID];
                                // if (tgtNodeID !== targetNodeID) {
                                if (!checkedNodes.has(tgtNodeID)) {
                                    // stop infinite loops
                                    checkTargetGraph(tgtNodeID);
                                }
                            });
                    };

                    checkTargetGraph(targetNodeID);
                }

                return get().flow.flowGraph;
            },

            connectDataPorts: (sourceNodeID, sourceEdgeIndex, targetNodeID, targetEdgeIndex) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const debug = current(flowGraph);
                        const { nodes, edges, nextEdgeID } = flowGraph;
                        const sourceNode = nodes[sourceNodeID];
                        const sourceOutput = sourceNode.outputs[sourceEdgeIndex];
                        const targetNode = nodes[targetNodeID];
                        const targetInput = targetNode.inputs[targetEdgeIndex];
                        const targetEdgeID = targetInput.edgeID;
                        if (!sourceOutput.edgeIDs.includes(targetEdgeID)) {
                            // for now, simple node position check to see if edge is going backwards
                            // hey!! may be deprecated - the new GraphEdge.js does this check itself now, see this check elsewhere???
                            const recursingEdge = sourceNode.position.x >= targetNode.position.x;
                            const sourcePathName = `${sourceNode.nodeName || sourceNode.name}.${sourceOutput.pathName}`;
                            const sourceType = sourceOutput.type;
                            edges[nextEdgeID] = {
                                edgeID: nextEdgeID,
                                type: "data-flow",
                                recursingEdge,
                                sourceNodeID: sourceNode.nodeID,
                                targetNodeID: targetNode.nodeID,
                                sourcePathName,
                                sourceType,
                            };
                            sourceOutput.edgeIDs.push(nextEdgeID);
                            setInputMapping(targetNode, targetInput.pathName, sourcePathName, sourceType);
                            targetInput.edgeID = nextEdgeID;
                            flowGraph.nextEdgeID += 1;
                        }
                    })
                );
                return get().flow.flowGraph;
            },

            deleteProcessConnection: (edgeID, sourceNodeID, sourceEdgeIndex, targetNodeID) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes, edges } = flowGraph;
                        const sourceNode = nodes[sourceNodeID];
                        const targetNode = nodes[targetNodeID];
                        if (sourceEdgeIndex >= 0) sourceNode.exits[sourceEdgeIndex].edgeID = null;
                        const entry = targetNode.entries.find(en => en.edgeID === edgeID);
                        if (entry) {
                            entry.edgeID = null;
                            entry.label = "empty";
                        }
                        delete edges[edgeID];
                        const forDebug = current(flowGraph);
                    })
                );
                return get().flow.flowGraph;
            },

            deleteDataConnection: (edgeID, sourceNodeID, sourceEdgeIndex, targetNodeID, targetEdgeIndex) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes, edges } = flowGraph;
                        const sourceNode = nodes[sourceNodeID];
                        const targetNode = nodes[targetNodeID];
                        const edgeIDIndex = sourceNode.outputs[sourceEdgeIndex].edgeIDs.indexOf(edgeID);
                        if (edgeIDIndex >= 0) {
                            sourceNode.outputs[sourceEdgeIndex].edgeIDs.splice(edgeIDIndex, 1);
                        }
                        if (targetEdgeIndex >= 0) {
                            targetNode.inputs[targetEdgeIndex].edgeID = null;
                            deleteInputMapping(targetNode, targetNode.inputs[targetEdgeIndex].pathName);
                        }
                        delete edges[edgeID];
                        const forDebug = current(flowGraph);
                    })
                );
                return get().flow.flowGraph;
            },

            deleteNode: nodeID => {
                const state = get();
                const flowGraph = state.flow.flowGraph;
                const { nodes, edges } = flowGraph;
                const node = nodes[nodeID];
                // first drop any connected process-flow edges
                const exitEdges = node.exits
                    .filter(ex => ex.edgeID && edges[ex.edgeID])
                    .map((ex, index) => ({
                        edge: edges[ex.edgeID],
                        edgeID: ex.edgeID,
                        index,
                    }));
                const entryEdges = node.entries
                    .filter(en => en.edgeID && edges[en.edgeID])
                    .map((en, index) => ({
                        edge: edges[en.edgeID],
                        edgeID: en.edgeID,
                        index,
                    }));
                exitEdges.forEach(({ edge, edgeID, index }) => {
                    const targetNode = nodes[edge.targetNodeID];
                    state.methods.deleteProcessConnection(edgeID, nodeID, index, targetNode.nodeID);
                });
                entryEdges.forEach(({ edge, edgeID }) => {
                    const sourceNode = nodes[edge.sourceNodeID];
                    const sourceEdgeIndex = sourceNode.exits.findIndex(ex => ex.edgeID === edgeID);
                    state.methods.deleteProcessConnection(edgeID, sourceNode.nodeID, sourceEdgeIndex, nodeID);
                });

                // the drop any connected data-flow edges
                const outputEdges = [];
                node.outputs.forEach((op, index) =>
                    op.edgeIDs.filter(edgeID => edges[edgeID]).forEach(edgeID => outputEdges.push({ edge: edges[edgeID], edgeID, index }))
                );
                const inputEdges = node.inputs
                    .filter(ip => ip.edgeID && edges[ip.edgeID])
                    .map((ip, index) => ({
                        edge: edges[ip.edgeID],
                        edgeID: ip.edgeID,
                        index,
                    }));
                outputEdges.forEach(({ edge, edgeID, index }) => {
                    const targetNode = nodes[edge.targetNodeID];
                    const targetEdgeIndex = targetNode.inputs.findIndex(ip => ip.edgeID === edgeID);
                    state.methods.deleteDataConnection(edgeID, nodeID, index, targetNode.nodeID, targetEdgeIndex);
                });
                inputEdges.forEach(({ edge, edgeID, index }) => {
                    const sourceNode = nodes[edge.sourceNodeID];
                    const sourceEdgeIndex = sourceNode.outputs.findIndex(op => op.edgeIDs.includes(edgeID));
                    state.methods.deleteDataConnection(edgeID, sourceNode.nodeID, sourceEdgeIndex, nodeID, index);
                });

                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        if (nodeID === flowGraph.startNodeID) {
                            flowGraph.startNodeID = Object.values(nodes).find(n => n.nodeID !== nodeID)?.nodeID || undefined; // just any other node as the new start node
                        }
                        delete nodes[nodeID];
                    })
                );
                const forDebug = get().flow.flowGraph;
                return get().flow.flowGraph;
            },

            deleteNodes: nodeIDs => {
                const deleteNode = get().methods.deleteNode;
                for (const id of nodeIDs) deleteNode(id);
                return get().flow.flowGraph;
            },

            duplicateNode: nodeID => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        const sourceNode = nodes[nodeID];
                        const newNode = cloneDeep(sourceNode);
                        newNode.created = Date.now();
                        newNode.entries.forEach(en => {
                            en.edgeID = null;
                        });
                        newNode.exits.forEach(ex => {
                            ex.edgeID = null;
                        });
                        newNode.inputs.forEach(inp => {
                            inp.edgeID = null;
                        });
                        newNode.outputs.forEach(inp => {
                            inp.edgeID = null;
                        });
                        newNode.nodeID = state.flow.flowGraph.nextNodeID;
                        newNode.position = {
                            x: sourceNode.position.x + 40,
                            y: sourceNode.position.y + 40,
                        };
                        const nextNum = Object.values(nodes).reduce((a, n) => a + (n.name === newNode.name ? 1 : 0), 1);
                        newNode.nodeName = `${newNode.name}${nextNum.toString().padStart(2, "0")}`;
                        flowGraph.nextNodeID += 1;
                        nodes[newNode.nodeID] = newNode;
                        const forDebug = current(flowGraph);
                    })
                );
                return get().flow.flowGraph;
            },

            unlockNodeParameters: nodeID => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        const node = nodes[nodeID];
                        const recursiveUnlock = param => {
                            if (param instanceof DataValue) {
                                if (param.locked) {
                                    param.locked = false;
                                    // param.value = undefined;  // reset value too??
                                }
                            } else if (typeof param === "object") {
                                Object.values(param).forEach(prop => recursiveUnlock(prop));
                            }
                        };
                        recursiveUnlock(node.parameters);
                        const forDebug = current(node);
                    })
                );
                return get().flow.flowGraph;
            },

            setFlowGraph: flowGraph =>
                set(
                    produce(state => {
                        state.flow = cloneDeep(initStore.flow);
                        state.flow.flowGraph = flowGraph;
                        state.flow.runState = flowGraph ? "loaded" : "idle";
                    })
                ),

            setSession: (sessionID, sessionGuid) =>
                set(
                    produce(state => {
                        state.flow.sessionID = sessionID;
                        state.flow.sessionGuid = sessionGuid;
                    })
                ),

            setSessionStep: (stepID, nodeID) =>
                set(
                    produce(state => {
                        state.flow.sessionStepID = stepID;
                        state.flow.currentNodeID = nodeID;
                    })
                ),

            setNodeReady: (nodeID, ready) =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].nodeReady[nodeID] = ready;
                    })
                ),

            configureNode: oldNode =>
                set(
                    produce(state => {
                        //prepare node for configuration step
                        flowgraphStoreUnSubscribeAll();
                        const node = state.flow.flowGraph.nodes[oldNode.nodeID];
                        // console.log('*** configureNode, oldNode', oldNode.nodeID, oldNode)
                        // console.log('       node', node.nodeID, current(node))
                        state.flow.steps = [cloneDeep(initStep)];
                        state.flow.steps[0].node = node;
                        state.flow.steps[0].nodeID = node.nodeID;
                        state.flow.steps[0].parameters = cloneDeep(node.parameters);
                        node.inputs = cloneDeep(node.inputs);
                        node.inputs
                            .filter(ip => ip.controlledBy)
                            .forEach(ip => {
                                ip.hidden = true;
                            });
                        state.flow.currentStepIndex = 0;
                        state.flow.currentNodeID = node.nodeID;
                        state.flow.runState = "configuring";
                    })
                ),

            initNodeParameters: node =>
                set(
                    produce(state => {
                        state.flow.steps[0].parameters = cloneDeep(node.parameters);
                    })
                ),

            isParameterDefined: pathName => {
                const state = get();
                const step = state.flow.steps[state.flow.currentStepIndex];
                const parameters = get().flow.steps[step.index].parameters;
                const parameter = getByPath(parameters, pathName);
                return parameter && parameter.value !== undefined && parameter.value !== null;
            },

            showParameterHistory: paramUI =>
                set(
                    produce(state => {
                        const step = state.flow.steps[state.flow.currentStepIndex];
                        const debug = current(step);
                        const { node } = step;
                        if (node?.flags?.hideParamsInSessionLog !== true)
                            // node?.flags?.collapseParameters !== true || (node?.flags?.collapseParameters && paramUI.closed)) - flag deprecated in new UI
                            step.showParameterHistory = true;
                    })
                ),

            setServiceParameterSpecs: (node, service, parameterSpecs) =>
                set(
                    produce(state => {
                        // record the service details in the flowGraph node
                        const serviceKey = [service.serviceFamily, service.serviceType];
                        state.flow.flowGraph.nodes[node.nodeID].services[serviceKey] = { ...service, parameterSpecs };
                    })
                ),

            setParamLock: (i, lockState) => {
                // turns on and off locking for a parameter
                const { clearDependentParameters } = get().methods;
                let resetting = false;
                let pathName;
                let parameter;
                set(
                    produce(state => {
                        const step = state.flow.steps[state.flow.currentStepIndex];
                        const paramUI = step.parameterUI[i];
                        const parameters = step.parameters;
                        parameter = getByPath(parameters, paramUI.pathName);
                        pathName = paramUI.pathName;
                        let isDefined = parameter && parameter.value !== undefined && parameter.value !== null;

                        if (!isDefined && paramUI.defaultValue) {
                            parameter = DataValue.makeFrom(current(paramUI.defaultValue));
                            isDefined = true;
                        }
                        if (isDefined && !(parameter instanceof DataValue)) {
                            parameter = DataValue.makeFrom(parameter);
                        }

                        if (lockState) {
                            paramUI.locked = true;
                            if (isDefined) {
                                paramUI.closed = true;
                                parameter.locked = true;
                            }
                        } else {
                            // const cpp = current(step.activeControllingParams);
                            paramUI.locked = false;
                            paramUI.closed = false;
                            if (isDefined) {
                                parameter.locked = false;
                                if (step.activeControllingParams.has(pathName)) {
                                    // if this is a controlling parameter, engage reset of dependents
                                    parameter.resetting = true;
                                    parameter.resetOnUnlock(); // let the parameter decide if it wants to reset on unlock
                                    // parameter.value = undefined;  leave old value if any; probably need an 'x' clear-value button
                                    resetting = true;
                                }
                            }
                        }
                        if (!isDraft(parameter)) {
                            // immer doesn't proxy class instances, so doesn't see their prop changes, force whole parameter update
                            // !! I think this is fixed, DataValues are now marked immerable - check!!
                            setByPath(parameters, paramUI.pathName, parameter);
                        }
                    })
                );

                if (resetting) {
                    // clear all dependent params, mild hack??
                    clearDependentParameters(pathName);
                }
                return resetting;
            },

            setControllingParameter: pathName =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].controllingParameter = pathName;
                    })
                ),

            addControlledSpec: spec => {
                // build the dependency graph if there is a current controlling parameter set
                if (spec.paramSpec.pathName) {
                    set(
                        produce(state => {
                            // const debug = current(state.flow);
                            const { controllingParameter, activeControllingParams } = state.flow.steps[state.flow.currentStepIndex];
                            if (controllingParameter) {
                                spec.paramUI.controlledBy = controllingParameter;
                                activeControllingParams.add(controllingParameter);
                            }
                        })
                    );
                }
            },

            clearDependentParameters: resettingParameterName =>
                set(
                    produce(state => {
                        const debug = current(state.flow);
                        const {
                            flow: {
                                steps,
                                flowGraph: { nodes },
                                currentNodeID,
                            },
                        } = state;
                        const step = steps[state.flow.currentStepIndex];
                        const { parameterUI, parameters } = step;
                        const node = nodes[step.nodeID];

                        const clearDependents = controllingParam => {
                            console.log("clearing dependents for", controllingParam);

                            parameterUI.forEach(paramUI => {
                                if (
                                    !paramUI.dead &&
                                    (paramUI.controlledBy === controllingParam ||
                                        (paramUI.pathName !== resettingParameterName &&
                                            paramUI.pathName === controllingParam &&
                                            paramUI.controlledBy))
                                ) {
                                    paramUI.dead = true;
                                    step.activeControllingParams.delete(paramUI.pathName);
                                    const parameter = getParameter(parameters, paramUI.pathName);
                                    if (parameter && paramUI.pathName !== controllingParam) {
                                        if (parameter.shouldClearOnReset()) {
                                            parameter.value = undefined;
                                            parameter.locked = false;
                                        }
                                        setByPath(parameters, paramUI.pathName, parameter);
                                    }

                                    const input = node.inputs.find(ip => ip.pathName === paramUI.pathName);
                                    console.log("  input", input);
                                    if (input) {
                                        // for now, just mark resetting parameter inputs as hidden, keep any wiring in place
                                        input.hidden = true;
                                    }

                                    if (paramUI.pathName !== controllingParam) clearDependents(paramUI.pathName);
                                }
                            });
                        };
                        clearDependents(resettingParameterName);
                        step.controllingParameter = null;
                        const s = current(step);
                    })
                ),

            startParameterResetting: resettingParameterName =>
                set(
                    produce(state => {
                        // resets the parameter input state from the resetting parameter onwards
                        const currentStep = state.flow.steps[state.flow.currentStepIndex];
                        const debug = current(currentStep);
                        const pi = currentStep.parameterUI.findIndex(pui => pui.pathName === resettingParameterName);
                        const removedPUIs = currentStep.parameterUI.splice(Math.max(0, pi + 1));
                        const debug2 = current(currentStep);
                        currentStep.displays = [];
                        removedPUIs.forEach(rpui => currentStep.activeControllingParams.delete(rpui));
                        if (!state.flow.runState.match(/-resetting$/)) state.flow.runState = `${state.flow.runState}-resetting`;
                    })
                ),

            endParameterResetting: paramUI =>
                set(
                    produce(state => {
                        // pulls out of the parameter resetting flow state for the resetting parameter
                        const paramUIs = state.flow.steps[state.flow.currentStepIndex].parameterUI;
                        // const resettingIndex = paramUIs.findIndex((paramUI, i) => paramUI.resetting);
                        // if (resettingIndex >= 0) {
                        //     paramUIs[resettingIndex].resetting = false;
                        // }
                        paramUIs[paramUI.index].resetting = false;
                        state.flow.runState = state.flow.runState.replace(/(-resetting)+/, "");
                    })
                ),

            addStepDisplay: display =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].displays.push(display);
                    })
                ),

            setStepDisplay: display =>
                set(
                    produce(state => {
                        const c = current; // for debugging
                        const displays = state.flow.steps[state.flow.currentStepIndex].displays;
                        const target = displays.find(d => Object.keys(display).every(k => k in d));
                        if (target) {
                            for (const k in display) target[k] = display[k];
                        } else {
                            displays.push(display);
                        }
                    })
                ),

            setConfigurationPreview: preview =>
                set(
                    produce(state => {
                        state.flow.steps[0].configurationPreview = preview;
                    })
                ),

            copyStepParamsToNode: (nodeID, options = {}) => {
                set(
                    produce(state => {
                        const before = current(state.flow);
                        // const beforeParams = current(state.flow.steps[0].parameters);
                        const { parameters } = state.flow.steps[state.flow.currentStepIndex];
                        if (state.flow.steps[state.flow.currentStepIndex].nodeID === nodeID) {
                            const node = state.flow.flowGraph.nodes[nodeID];
                            if (node) {
                                if (node.parameters) {
                                    merge(node.parameters, parameters);
                                } else {
                                    node.parameters = parameters;
                                }
                            }
                        } else {
                            console.log("!!!!! updating wrong node!!!");
                        }
                        if (options.clearCurrentNode) state.flow.currentNodeID = null;
                        // const forDebug = current(state.flow.flowGraph);
                    })
                );
                return get().flow.flowGraph;
            },

            findService: ({ serviceType, serviceFamily }) =>
                get().tools.adapters.find(a => a.serviceFamily === serviceFamily && a.serviceType === serviceType),

            setStepMetadata: metadata =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].metadata = metadata;
                    })
                ),

            // may be deprecated
            setStepSummary: summary =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].summary = summary;
                    })
                ),

            setGeneration: response =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].generation = response;
                    })
                ),

            setBusy: (busy, message = "") =>
                set(
                    produce(state => {
                        // state.flow.busy = true;
                        if (state.flow.busyDebounce) clearTimeout(state.flow.busyDebounce);
                        state.flow.busyDebounce = null;
                        if (busy) {
                            state.flow.busyMessage = message;
                            state.flow.busyStatus = "";
                            state.flow.busyDebounce = setTimeout(
                                () =>
                                    set(
                                        produce(state => {
                                            state.flow.busy = true;
                                        })
                                    ),
                                250
                            );
                        } else {
                            state.flow.busy = false;
                            state.flow.busyMessage = "";
                            state.flow.busyStatus = "";
                        }
                    })
                ),

            setBusyStatus: status =>
                set(
                    produce(state => {
                        state.flow.busyStatus = status;
                    })
                ),

            setEdgeHighlight: (nodeID, edgeID, onOff) =>
                set(
                    produce(state => {
                        state.flow.highlightedEdge = onOff ? { nodeID, edgeID } : {};
                    })
                ),

            addUIForParameter: paramUI =>
                set(
                    produce(state => {
                        const flow = state.flow;
                        // const debug = current(flow);
                        const {
                            flowGraph: { nodes },
                            steps,
                            currentStepIndex,
                            runState,
                        } = flow;
                        const step = steps[currentStepIndex];
                        const { controllingParameter, parameterUI } = step;
                        // during resetting, we don't add the parameters earlier than the resetting parameter, all those already in the parameterUI list, etc.
                        const presentIndex = paramUI.pathName && parameterUI.findIndex(pui => pui.pathName === paramUI.pathName);
                        if (runState.endsWith("-resetting") || presentIndex >= 0) {
                            paramUI.index = presentIndex;
                            return;
                        }
                        const node = nodes[state.flow.currentNodeID];
                        // add any conditional parameters to the input list as they become visible in the parameter UI
                        if (
                            DataValue.isWireableType(paramUI.type) &&
                            !paramUI.notWireable &&
                            runState.startsWith("configuring") &&
                            controllingParameter &&
                            paramUI.pathName &&
                            node
                        ) {
                            const input = node.inputs.find(ip => ip.pathName === paramUI.pathName);
                            if (input) {
                                input.hidden = false;
                                input.controlledBy = controllingParameter;
                            } else {
                                const { pathName, type, label, serviceGuid } = paramUI;
                                node.inputs.push({
                                    pathName,
                                    type,
                                    label,
                                    edgeID: null,
                                    serviceGuid,
                                    controlledBy: controllingParameter,
                                    source: "parameter",
                                });
                            }
                        }

                        // add the paramUI and an empty parameter for it if not in the parameter namespace already
                        if (
                            paramUI.pathName &&
                            paramUI.type &&
                            DataValue.classFromType(paramUI.type) &&
                            getByPath(step.parameters, paramUI.pathName) === undefined
                        ) {
                            setByPath(step.parameters, paramUI.pathName, DataValue.makeFrom({ type: paramUI.type, value: undefined }));
                        }
                        step.parameterUI.push(paramUI);
                    })
                ),

            closeParamUI: (paramUI, closed) =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].parameterUI[paramUI.index].closed = closed;
                    })
                ),

            setParamUILoading: loading =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].paramUILoading = loading;
                    })
                ),

            setGatheringParameters: gathering =>
                set(
                    produce(state => {
                        const {
                            flow: { steps, currentStepIndex },
                        } = state;
                        const step = steps[currentStepIndex];
                        step.gatheringParameters = gathering;
                        if (gathering) {
                            state.methods.resetHadParameterInput();
                        }
                    })
                ),

            resetHadParameterInput: () =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].hadParameterInput = false;
                    })
                ),

            noteHadParameterInput: hadInput =>
                set(
                    produce(state => {
                        state.flow.steps[state.flow.currentStepIndex].hadParameterInput ||= hadInput;
                    })
                ),

            allParametersLocked: paramUI => {
                const {
                    flow: { steps, currentStepIndex },
                } = get();
                const step = steps[currentStepIndex];
                return step.parameterUI
                    .filter(pui => pui !== paramUI && "parameter" in pui)
                    .every(pui => pui.parameter?.locked || (pui.configureOnly && pui.parameter?.value));
            },

            allParametersReady: () => {
                // ensure all parameters presented to the user now have defined values
                const {
                    flow: { steps, currentStepIndex },
                } = get();
                const step = steps[currentStepIndex];
                return step.parameterUI
                    .filter(pui => "parameter" in pui)
                    .every(pui => pui.parameter?.locked || (pui.parameter?.value && pui.parameter.value === undefined));
            },

            updateParameterSpec: (nodeID, paramUI, update) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        const node = nodes[nodeID];
                        const paramSpec = node.parameterSpecs.find(spec => spec.pathName === paramUI.pathName);
                        if (paramSpec) {
                            for (const [k, v] of Object.entries(update)) {
                                paramSpec[k] = v;
                            }
                        }
                        // update paramUI
                        const parameterUI = state.flow.steps[state.flow.currentStepIndex].parameterUI;
                        const updatingParamUI = parameterUI[paramUI.index]; // .find(pui => pui.pathName === paramUI.pathName);
                        if (updatingParamUI) {
                            for (const [k, v] of Object.entries(update)) {
                                updatingParamUI[k] = v;
                            }
                        }
                        const forDebug = current(flowGraph);
                    })
                );
                return get().flow.flowGraph;
            },

            updateExits: (nodeID, updatedExits) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        const node = nodes[nodeID];
                        node.exits = updatedExits;
                        const forDebug = current(flowGraph);
                    })
                );
                return get().flow.flowGraph;
            },

            setInputs: (nodeID, inputs) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        const node = nodes[nodeID];
                        node.inputs = inputs;
                    })
                );
                return get().flow.flowGraph;
            },

            setOutputs: (nodeID, outputs) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { nodes } = flowGraph;
                        const node = nodes[nodeID];
                        node.outputs = outputs;
                    })
                );
                return get().flow.flowGraph;
            },

            updateEdgeProps: (edgeID, props) => {
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        const { edges } = flowGraph;
                        const edge = edges[edgeID];
                        for (const prop in props) {
                            edge[prop] = props[prop];
                        }
                    })
                );
                return get().flow.flowGraph;
            },

            wrapParameter: (pathName, parameter) =>
                set(
                    produce(state => {
                        const step = state.flow.steps[state.flow.currentStepIndex];
                        setByPath(step.parameters, pathName, DataValue.makeFrom(parameter));
                    })
                ),

            setParameter: (pathName, value, forceStateUpdate = false) => {
                let needsReset = false;

                set(
                    produce(state => {
                        const debug = current(state.flow);
                        // if same value already in place, need to null & set again to force a state change
                        const step = state.flow.steps[state.flow.currentStepIndex];
                        const oldValue = getByPath(step.parameters, pathName);
                        const oldCurrentalue = oldValue && current(oldValue);
                        if (value === oldCurrentalue) {
                            setByPath(step.parameters, pathName, null);
                        }
                    })
                );

                set(
                    produce(state => {
                        // console.log("setParameter", value);
                        const step = state.flow.steps[state.flow.currentStepIndex];
                        setByPath(step.parameters, pathName, value);
                        needsReset = step.activeControllingParams.has(pathName);
                    })
                );

                if (needsReset) {
                    let resettingParameterName;
                    set(
                        produce(state => {
                            if (!state.flow.runState.match(/-resetting$/)) state.flow.runState = `${state.flow.runState}-resetting`;
                            const { parameters } = state.flow.steps[state.flow.currentStepIndex];
                            const parameter = getByPath(parameters, pathName);
                            parameter.resetting = true;
                            // parameter.value = undefined;
                            resettingParameterName = pathName;
                            if (!isDraft(parameter)) {
                                // immer won't proxy into class instances, it seems
                                setByPath(parameters, pathName, parameter);
                            }
                        })
                    );
                    // clear all dependent params, mild hack??
                    flowgraphStoreUnSubscribeAll();
                    get().methods.clearDependentParameters(resettingParameterName);
                    flowgraphEngine.resetParameter(resettingParameterName);
                }
            },

            getParameter: pathName => {
                const {
                    flow: { steps, currentStepIndex },
                } = get();
                const parameters = steps[currentStepIndex].parameters;
                const parameter = getByPath(parameters, pathName);
                return parameter instanceof DataValue ? parameter : parameter ? DataValue.makeFrom(parameter) : parameter;
            },

            runError: error =>
                set(
                    produce(state => {
                        const { flow } = state;
                        const {
                            currentNodeID,
                            flowGraph: { nodes },
                            steps,
                            currentStepIndex,
                        } = flow;
                        const node = nodes[currentNodeID];
                        flow.error = error;
                        steps[currentStepIndex].exception = {
                            node,
                            exception: error,
                        };
                    })
                ),

            resetFlowState: () =>
                set(
                    produce(state => {
                        flowgraphStoreUnSubscribeAll();
                        state.flow = cloneDeep(initStore.flow);
                    })
                ),

            stopRunning: () =>
                set(
                    produce(state => {
                        const flowGraph = state.flow.flowGraph;
                        flowgraphStoreUnSubscribeAll();
                        state.flow = cloneDeep(initStore.flow);
                        state.flow.flowGraph = flowGraph;
                    })
                ),

            resetCurrentFlow: () =>
                set(
                    produce(state => {
                        flowgraphStoreUnSubscribeAll();
                    })
                ),

            restartRun: () =>
                set(
                    produce(state => {
                        flowgraphStoreUnSubscribeAll();
                        state.flow = cloneDeep(initStore.flow);
                    })
                ),

            checkFlowGraph: flowGraph => {
                // check well-formedness
                const { edges, nodes } = flowGraph;
                let bad = [];
                Object.values(edges).forEach(e => {
                    if (e.sourceNodeID in nodes && e.targetNodeID in nodes) {
                        // check edge is in appropriate port slots
                        // sorry, no easy repair, thinking on it
                        const sourceNode = nodes[e.sourceNodeID];
                        const targetNode = nodes[e.targetNodeID];
                        if (e.type === "process-flow") {
                            if (sourceNode.exits.findIndex(ex => ex.edgeID === e.edgeID) < 0) {
                                bad.push({
                                    edgeID: e.edgeID,
                                    sourceNodeID: e.sourceNodeID,
                                });
                            }
                        } else {
                            // data-flow
                            if (sourceNode.outputs.findIndex(op => op.edgeIDs.includes(e.edgeID)) < 0) {
                                bad.push({
                                    edgeID: e.edgeID,
                                    sourceNodeID: e.sourceNodeID,
                                });
                            }
                            if (targetNode.inputs.findIndex(ip => ip.edgeID === e.edgeID) < 0) {
                                bad.push({
                                    edgeID: e.edgeID,
                                    targetNodeID: e.targetNodeID,
                                });
                            }
                        }
                    }
                    // check src & tgt nodes referenced in edges exist
                    if (!(e.sourceNodeID in nodes)) {
                        bad.push({
                            edgeID: e.edgeID,
                            sourceNodeID: e.sourceNodeID,
                        });
                        delete edges[e.edgeID];
                    }
                    if (!(e.targetNodeID in nodes)) {
                        bad.push({
                            edgeID: e.edgeID,
                            targetNodeID: e.targetNodeID,
                        });
                        delete edges[e.edgeID];
                    }
                });
                // check edge lists in ports exist
                Object.values(nodes).forEach(n => {
                    n.entries
                        .filter(en => en.edgeID && !(en.edgeID in edges))
                        .forEach(en => {
                            en.edgeID = null;
                            bad.push({ node: n, entryEdgeID: en.edgeID });
                        });
                    n.exits
                        .filter(ex => ex.edgeID && !(ex.edgeID in edges))
                        .forEach(ex => {
                            ex.edgeID = null;
                            bad.push({ node: n, exitEdgeID: ex.edgeID });
                        });
                    n.inputs
                        .filter(ip => ip.edgeID && !(ip.edgeID in edges))
                        .forEach(ip => {
                            ip.edgeID = null;
                            bad.push({ node: n, inputEdgeID: ip.edgeID });
                        });
                    n.outputs.filter(
                        op =>
                            op.edgeIDs &&
                            [...op.edgeIDs].forEach((eid, i) => {
                                if (!(eid in edges)) {
                                    op.edgeIDs = op.edgeIDs.filter(id => id !== eid);
                                    bad.push({ node: n, outputEdgeIDs: eid });
                                }
                            })
                    );
                });
                if (bad.length > 0) {
                    console.log("checkFlowGraph bad flowGraph", flowGraph.displayName, flowGraph._id?.toString());
                    bad.forEach(b => {
                        console.log("   ", b);
                    });
                }
            },

            getStartNode: _flowGraph => {
                const flowGraph = _flowGraph || get().flow.flowGraph;
                return flowGraph && flowGraph.startNodeID && flowGraph.nodes && flowGraph.nodes[flowGraph.startNodeID];
            },

            reset: () =>
                set(
                    produce(state => {
                        state = cloneDeep(initStore);
                    })
                ),
        },
    }))
);

export const useFlowGraphStoreMethods = () => useFlowGraphStore(state => state.methods, shallow);
export const useFlowGraphStoreAndMethods = (stateGetter, flags) => [
    useFlowGraphStore(stateGetter, flags),
    useFlowGraphStore(state => state.methods, shallow),
];
export const useFlowGraphStoreVars = (
    ...args // ('var1', 'var2', ...)
) => useFlowGraphStore(state => args.map(prop => state[prop]), shallow);

// this manages explicit subscriptions to the store from non-React components, tracking those that need to be unsubscribed on a reset
// these are one-shot subscriptions, typically to parameter slots in the current step parameter copy, the resolve() call usually satisfies a wait on param input
const subscriptions = [];
export const flowgraphStoreSubscribe = (getSubState, resolve, reject) => {
    const unsubscribe = useFlowGraphStore.subscribe(
        state => getSubState(state),
        async subState => {
            const unsubIndex = subscriptions.find(s => s === unsubscribe);
            if (unsubIndex >= 0) subcriptions.splice(unsubIndex, 1);
            unsubscribe();
            resolve(subState);
        }
    );
    subscriptions.push(unsubscribe);
};
export const flowgraphStoreUnSubscribeAll = () => {
    subscriptions.forEach(unsubscribe => unsubscribe());
    subscriptions.splice(0);
};

// custom use-hook to check on parameter input enablement based any declared dependencies for current step
export const useParameterDisabledCheck = paramUI => {
    const parameters = useFlowGraphStore(state => state.flow.steps[state.flow.currentStepIndex].parameters);
    return paramUI.dependsOn?.some(paramPath => {
        const param = getByPath(parameters, paramPath);
        return param === undefined || param.value === undefined;
    });
};

// custom use-hook to get latest parameter for given step & pathname
export const useParameter = (step, pathName) => {
    const parameters = useFlowGraphStore(state => {
        return state.flow.flowGraph ? state.flow.steps[step.index].parameters : [];
    });
    const parameter = getByPath(parameters, pathName);
    return parameter instanceof DataValue ? parameter : parameter ? DataValue.makeFrom(parameter) : parameter;
};

// A class hierachy instances of which wrap parameter values used in flow-graph & service definition & running
import { immerable, produce } from "immer";
import beautify from "json-beautify";
import { httpAPI } from "@mirinae/apis/http";
import { apiPaths } from "@mirinae/shared/modules/defines/paths";
import { getRandomPastelColor } from "@mirinae/shared/modules/utils/formatters";

export class DataValue {
    [immerable] = true;

    constructor(type, value, locked = false, label = "", resetting = false) {
        this.type = type;
        this.value = value === null ? undefined : value;
        this.label = label;
        this.locked = locked;
        if (resetting) this.resetting = resetting;
    }

    static classFromType(type) {
        return {
            batch: BatchValue,
            boolean: BooleanValue,
            branch: BranchValue,
            choice: ChoiceValue,
            code: CodeValue,
            completion: CompletionValue,
            compositePrompt: CompositePromptValue,
            contentSelection: ContentSelectionValue,
            content: ContentSelectionValue, // for backwards compatibility
            counter: CounterValue,
            cssSelectors: CSSSelectorsValue,
            directorySelect: DirectorySelectValue,
            embeddingSelection: EmbeddingSelectionValue,
            embeddings: EmbeddingSelectionValue, // for backwards compatibility
            embeddingSet: EmbeddingSetValue,
            embeddingVector: EmbeddingVectorValue,
            embeddingVectors: EmbeddingVectorsValue,
            exception: ExceptionValue,
            fileUpload: UploadsValue,
            image: MediaValue,
            media: MediaValue,
            importSet: ImportSetValue,
            instructions: InstructionsValue,
            knowledge: KnowledgeValue,
            loopControl: LoopControlValue,
            matchSpecs: MatchSpecsValue,
            metadata: MetaDataValue,
            model: ModelValue,
            modelList: ModelListValue,
            number: NumberValue,
            "Number compatible": NumberValue,
            object: ObjectValue,
            options: OptionsValue,
            pathName: PathNameValue,
            prompt: PromptValue,
            promptButtons: PromptButtonsValue,
            prompTemplate: PromptTemplateValue,
            segmentationSet: SegmentationSetValue,
            segmentSelection: SegmentSelectionValue,
            segments: SegmentSelectionValue, // for backwards compatibility
            service: ServiceValue,
            slider: SliderValue,
            template: PromptTemplateValue, // for backwards compatibility
            text: TextValue,
            "Text compatible": TextValue,
            tools: ToolsValue,
            url: URLValue,
            vectorDB: VectorDBValue,
            knowledgeDB: KnowledgeDBValue,
            importable: ImportableValue,
            color: ColorValue,
        }[type];
    }

    static makeFrom(_src, type) {
        // src can be an object with a .type prop or a value for a new instance when the optional type argument is used
        // if type not supplied & src not object with .type, returns src unchanged
        let src = _src;
        if (src instanceof DataValue) return src;
        if ((src === undefined || src === null) && !type) {
            return src;
        }
        if (!src.type) {
            // src is possible wrapped value if type arg supplied
            if (!type) return src;
            else {
                src = { type, value: src };
            }
        }
        const cls = this.classFromType(src.type);
        if (!cls) {
            debugger;
            return undefined;
        }
        return new cls(src.value, src.locked, src.label, src.resetting);
    }

    static unWireableTypes = ["branch", "fileUpload"];

    static isWireableType(type) {
        return this.classFromType(type) && !this.unWireableTypes.includes(type);
    }

    static isAcceptableInputType(sourceType, targetType) {
        const targetTypes = targetType.split("|");
        const sourceTypes = sourceType.split("|");
        return (
            sourceTypes.some(st => targetTypes.includes(st)) ||
            targetTypes.some(tt => sourceTypes.some(st => DataValue.makeFrom({ type: tt }).isAcceptableInputType(st)))
        );
    }

    // is this used anywhere??
    static toText(type, value) {
        return this.makeFrom({ type, value }).toText();
    }

    toString() {
        return this.value?.toString() || "";
    }

    toJSON() {
        return { type: this.type, value: this.value, label: this.label, locked: this.locked };
    }

    toPromptContent(spec) {
        return { type: this.type, mimetype: "text/plain", content: this.value?.toString() || "" };
    }

    toTextList() {
        return [this.toString()];
    }

    toImportableData() {
        throw new Error(`Unable to convert ${this.type} to importable data`);
    }

    toNumber() {
        throw new Error(`Unable to convert ${this.type} to a number`);
    }

    get importSetIDList() {
        throw new Error(`Unable to convert ${this.type} to a list of content items`);
    }

    get segmentIDList() {
        throw new Error(`Unable to convert ${this.type} to a list of segment items`);
    }

    knowledgeSegmentList() {
        throw new Error(`Unable to convert ${this.type} to a list of knowledge segments`);
    }

    merge(value) {
        throw new Error(`Unable to merge ${value.type} into ${this.type}`);
    }

    displayValue() {
        return this.value;
    }

    directoryPopoverContents(objectMap) {
        return [];
    }

    isAcceptableInputType(sourceType) {
        return false;
    }

    inflateFromDirectoryEntry(selection) {
        return this;
    }

    // the following are defined as computed properties so that statically-defined parameterSpecs can reference them directly
    get relatedService() {
        return undefined;
    }

    get relatedObject() {
        return this.value;
    }

    get lockable() {
        return true;
    }

    resetOnUnlock() {}

    shouldClearOnReset() {
        return true;
    }

    resetOnLoad() {
        return false;
    }
}

const TextCompatible = Base =>
    class extends Base {
        isAcceptableInputType(sourceType) {
            return [
                "text",
                "prompt",
                "instruction",
                "knowledge",
                "url",
                "number",
                "counter",
                "model",
                "cssSelectors",
                "choice",
                "pathName",
                "code",
            ].includes(sourceType);
        }

        toImportableData() {
            return { dataType: "text", mimetype: "text/plain", data: this.toString() };
        }

        knowledgeSegmentList() {
            // crude for now, split any text type at \n\n
            return this.toString()
                .split("\n\n")
                .map(p => ({ text: p }));
        }

        toNumber() {
            try {
                return Number(this.toString());
            } catch (e) {
                throw new Error(`Unable to convert ${this.type} value "${this.toString()}" to a number`);
            }
        }
    };

const NumberCompatible = Base =>
    class extends Base {
        isAcceptableInputType(sourceType) {
            return ["number", "counter", "boolean", "branch", "text"].includes(sourceType);
        }
        toNumber() {
            return Number(this.value);
        }
    };

export class PromptValue extends TextCompatible(DataValue) {
    constructor(value, locked, label = "Prompt") {
        super("prompt", value, locked, label);
    }
}

export class InstructionsValue extends TextCompatible(DataValue) {
    constructor(value, locked, label = "Instructions") {
        super("instructions", value, locked, label);
    }
}

export class TextValue extends TextCompatible(DataValue) {
    constructor(value, locked, label) {
        super("text", value, locked, label);
    }
}

export class URLValue extends TextCompatible(DataValue) {
    constructor(value, locked, label) {
        super("url", value, locked, label);
    }
}

export class CSSSelectorsValue extends TextCompatible(DataValue) {
    constructor(value, locked, label) {
        super("cssSelectors", value, locked, label);
    }

    shouldClearOnReset() {
        return false;
    }
}

export class NumberValue extends NumberCompatible(DataValue) {
    constructor(value, locked, label) {
        super("number", value, locked, label);
    }
}

export class SliderValue extends NumberCompatible(DataValue) {
    constructor(value, locked, label) {
        super("slider", value, locked, label);
    }
}

export class CounterValue extends NumberCompatible(DataValue) {
    constructor(value, locked, label) {
        super("counter", value, locked, label);
    }
}

export class BooleanValue extends NumberCompatible(DataValue) {
    constructor(value, locked, label) {
        super("boolean", value, locked, label);
    }

    displayValue() {
        return this.value ? "true" : "false";
    }
}

export class ServiceValue extends DataValue {
    constructor(value, locked) {
        super("service", value, locked);
    }

    get relatedService() {
        return this.value;
    }

    displayValue() {
        return this.value.displayName;
    }
}

export class BranchValue extends NumberCompatible(DataValue) {
    constructor(value, locked, label) {
        super("branch", value, locked, label);
    }

    get lockable() {
        return false;
    }

    displayValue() {
        return this.label || (this.value + 1).toString();
    }
}

export class UploadsValue extends DataValue {
    constructor(value, locked, label) {
        super("fileUpload", value, locked, label);
    }

    displayValue() {
        return this.label;
    }
}

export class DirectorySelectValue extends DataValue {
    constructor(value, locked, label) {
        super("directorySelect", value, locked, label);
    }

    get relatedService() {
        // for singleton selections, looks for & returns relatedService prop, which will
        // have been added during direct-select post-processing for various selectable types, such as VectorDBs
        return this.value?.selection[0]?.relatedService;
    }

    get relatedObject() {
        return this.value?.selection[0]?.relatedObject;
    }

    // directory-selections reset on unlock
    resetOnUnlock() {
        this.value = undefined;
    }

    displayValue() {
        return this.value?.display;
    }
}

export class OptionsValue extends DataValue {
    constructor(value, locked, label) {
        super("options", value, locked, label);
    }
}

export class ChoiceValue extends TextCompatible(DataValue) {
    constructor(value, locked, label) {
        super("choice", value, locked, label);
    }
}

export class ObjectValue extends DataValue {
    constructor(value, locked, label) {
        super("object", value, locked, label);
    }
}

export class ModelListValue extends DataValue {
    constructor(value, locked, label) {
        super("modelList", value, locked, label);
    }

    toList() {
        return this.value;
    }
}

export class ModelValue extends TextCompatible(DataValue) {
    constructor(value, locked, label) {
        super("model", value, locked, label);
    }
}

export class PathNameValue extends TextCompatible(DataValue) {
    constructor(value, locked, label) {
        super("pathName", value, locked, label);
    }
}

export class CodeValue extends TextCompatible(DataValue) {
    constructor(value, locked, label) {
        super("code", value, locked, label);
    }
}

export class VectorDBValue extends DataValue {
    constructor(value, locked, label) {
        super("vectorDB", value, locked, label);
    }

    displayValue() {
        return this.value?.displayName;
    }

    async inflateFromDirectoryEntry(selection) {
        // load selected vectorDB object
        const {
            data: { vectorDB },
        } = await httpAPI("", apiPaths.getVectorDB, {
            data: { id: selection.objectID },
        });
        this.value = vectorDB;
        return this;
    }

    get relatedService() {
        return this.value?.service;
    }

    directoryPopoverContents(tagTreeObjectMap) {
        if (this.value) {
            const vdb = this.value;
            const vdbDirNodes = tagTreeObjectMap[vdb._id] || [];
            const tags = {
                tags: vdbDirNodes
                    .filter(dn => dn.tag.startsWith("User."))
                    .map(dn => dn.tag.replace(/^User./, ""))
                    .join(", "),
            };
            return [
                tags,
                { created: vdb.created },
                { label: "Vector count", value: vdb.vectorCount || "??" },
                { label: "Embedding service", value: vdb.embeddingParameters.service.displayName },
                { label: "Embedding model", value: vdb.embeddingParameters.model },
            ];
        }
    }
}

export class KnowledgeDBValue extends DataValue {
    constructor(value, locked, label) {
        super("knowledgeDB", value, locked, label);
    }

    displayValue() {
        return this.value?.displayName;
    }

    async inflateFromDirectoryEntry(selection) {
        // load selected knowledgeDB object
        const {
            data: { knowledgeDB },
        } = await httpAPI("", apiPaths.getVectorDB, {
            data: { id: selection.objectID },
        });
        this.value = knowledgeDB;
        return this;
    }

    get relatedService() {
        return this.value?.service;
    }

    directoryPopoverContents(tagTreeObjectMap) {
        if (this.value) {
            const vdb = this.value;
            const vdbDirNodes = tagTreeObjectMap[vdb._id] || [];
            const tags = {
                tags: vdbDirNodes
                    .filter(dn => dn.tag.startsWith("User."))
                    .map(dn => dn.tag.replace(/^User./, ""))
                    .join(", "),
            };
            return [
                tags,
                { created: vdb.created },
                { label: "Vector count", value: vdb.vectorCount || "??" },
                { label: "Embedding service", value: vdb.embeddingParameters.service.displayName },
                { label: "Embedding model", value: vdb.embeddingParameters.model },
            ];
        }
    }
}

export class K extends DataValue {
    constructor(value, locked, label) {
        super("knowledgeDB", value, locked, label);
    }

    displayValue() {
        return this.value?.displayName;
    }

    async inflateFromDirectoryEntry(selection) {
        // load selected knowledgeDB object
        const {
            data: { knowledgeDB },
        } = await httpAPI("", apiPaths.getVectorDB, {
            data: { id: selection.objectID },
        });
        this.value = knowledgeDB;
        return this;
    }

    get relatedService() {
        return this.value?.service;
    }

    directoryPopoverContents(tagTreeObjectMap) {
        if (this.value) {
            const vdb = this.value;
            const vdbDirNodes = tagTreeObjectMap[vdb._id] || [];
            const tags = {
                tags: vdbDirNodes
                    .filter(dn => dn.tag.startsWith("User."))
                    .map(dn => dn.tag.replace(/^User./, ""))
                    .join(", "),
            };
            return [
                tags,
                { created: vdb.created },
                { label: "Vector count", value: vdb.vectorCount || "??" },
                { label: "Embedding service", value: vdb.embeddingParameters.service.displayName },
                { label: "Embedding model", value: vdb.embeddingParameters.model },
            ];
        }
    }
}

export class PromptTemplateValue extends DataValue {
    constructor(value, locked, label) {
        super("prompTemplate", value, locked, label);
    }

    shouldClearOnReset() {
        return false;
    }

    displayValue() {
        return this.value ? beautify(this.value, null, 4, 120) : "[]";
    }
}

export const KnowledgeMixin = Base =>
    class extends Base {
        isAcceptableInputType(sourceType) {
            return ["segments"].includes(sourceType);
        }

        toString() {
            return this.value.segments?.length > 0 ? this.value.segments.join("\n") : this.value.text;
        }

        knowledgeSegmentList() {
            return this.value.segments || [{ text: this.value.text }];
        }
    };

export class KnowledgeValue extends KnowledgeMixin(TextCompatible(DataValue)) {
    constructor(value, locked, label) {
        super("knowledge", value, locked, label); // { text: 'xxx', segments: [{ text: '', relevanceScore: nn.nn, index: n },..]
    }
}

export class MatchSpecsValue extends DataValue {
    constructor(value, locked, label) {
        super("matchSpecs", value, locked, label);
    }
}

export class PromptButtonsValue extends DataValue {
    constructor(value, locked, label) {
        super("promptButtons", value, locked, label);
    }
    isAcceptableInputType(sourceType) {
        return ["text"].includes(sourceType);
    }
}

export class ContentSelectionValue extends DataValue {
    // contains a list of importSet IDs, not actual content item IDs
    // value is of the form [{ importSetID: <objectID> }, ...]

    constructor(value, locked, label) {
        super("contentSelection", value, locked, label);
    }

    isAcceptableInputType(sourceType) {
        return ["importSet", "content", "batch", "media", "image"].includes(sourceType); // some here for backwards compatibiity
    }

    get importSetIDList() {
        return this.value;
    }

    merge(value) {
        return new ContentSelectionValue(this.value.concat(value.importSetIDList));
    }
}

export class ImportSetValue extends DataValue {
    // ImportSet defines a set of Content objects, usually created during the same
    // import step.   To be replaced with ContentSet to better accommodate all forms of content creation
    // value is an ImportSet objectID as a string.

    constructor(value, locked, label) {
        super("importSet", value, locked, label);
    }

    get importSetID() {
        return this.value;
    }

    get importSetIDList() {
        return [{ importSetID: this.value }];
    }

    merge(value) {
        return new ContentSelectionValue(this.importSetIDList.concat(value.importSetIDList));
    }
}

export class SegmentSelectionValue extends KnowledgeMixin(DataValue) {
    constructor(value, locked, label) {
        super("segmentSelection", value, locked, label);
    }

    isAcceptableInputType(sourceType) {
        return ["segments", "segmentationSet"].includes(sourceType);
    }

    get segmentIDList() {
        return this.value;
    }

    merge(value) {
        return new SegmentSelectionValue(this.segmentIDList.concat(value.segmentIDList));
    }
}

export class SegmentationSetValue extends KnowledgeMixin(DataValue) {
    constructor(value, locked, label) {
        super("segmentationSet", value, locked, label);
    }

    get segmentIDList() {
        return [{ segmentationSetID: this.value }];
    }

    merge(value) {
        return new SegmentSelectionValue(this.segmentIDList.concat(value.segmentIDList));
    }
}

export class EmbeddingSetValue extends DataValue {
    // value is EmbeddingSet object ID as a string
    constructor(value, locked, label) {
        super("embeddingSet", value, locked, label);
    }

    get embeddingSetID() {
        return this.value;
    }

    get embeddingSetIDList() {
        return [{ embeddingSetID: this.value }];
    }

    merge(value) {
        return new EmbeddingSelectionValue(this.embeddingSetIDList.concat(value.embeddingSetIDList));
    }
}

export class EmbeddingSelectionValue extends DataValue {
    // value is a list of EmbeddingSet object IDS in the form [{ embeddingSetID: <objectID> }, ...]
    constructor(value, locked, label) {
        super("embeddingSelection", value, locked, label);
    }

    isAcceptableInputType(sourceType) {
        return ["embeddings", "embeddingSet"].includes(sourceType);
    }

    get embeddingSetIDList() {
        return this.value;
    }

    merge(value) {
        return new EmbeddingSelectionValue(this.embeddingSetIDList.concat(value.embeddingSetIDList));
    }
}

export class MediaValue extends DataValue {
    constructor(value, locked, label) {
        super("media", value, locked, label);
    }

    isAcceptableInputType(sourceType) {
        return ["content", "importSet", "image", "imageFile"].includes(sourceType); // some for backwards-compatibility
    }

    toImportableData() {
        return this.value;
    }

    toURL() {
        switch (this.value?.dataType) {
            case "gridfs": {
                return `/api/hyperflow/file/get/video/${this.value.data}`;
            }
            case "url": {
                return this.value.data;
            }
        }
    }
}

export class LoopControlValue extends DataValue {
    constructor(value, locked, label) {
        super("loopControl", value, locked, label);
    }
}

export class CompletionValue extends DataValue {
    constructor(value, locked, label) {
        super("completion", value, locked, label);
    }
}

export class EmbeddingVectorsValue extends DataValue {
    constructor(value, locked, label) {
        super("embeddingVectors", value, locked, label);
    }
}

export class EmbeddingVectorValue extends DataValue {
    constructor(value, locked, label) {
        super("embeddingVector", value, locked, label);
    }
}

export class ExceptionValue extends DataValue {
    constructor(value, locked, label) {
        super("exception", value, locked, label);
    }
}

export class CompositePromptValue extends DataValue {
    constructor(value, locked, label) {
        super("compositePrompt", value, locked, label);
    }
}

export class MetaDataValue extends DataValue {
    constructor(value, locked, label) {
        super("metadata", value, locked, label);
    }
}

export class ToolsValue extends DataValue {
    constructor(value, locked, label) {
        super("tools", value, locked, label);
    }
}

export class BatchValue extends DataValue {
    constructor(value, locked, label) {
        super("batch", value, locked, label);
    }
}

export class ImportableValue extends TextCompatible(DataValue) {
    constructor(value, locked, label) {
        super("importable", value, locked, label);
    }

    isAcceptableInputType(sourceType) {
        return super.isAcceptableInputType(sourceType) || ["image", "imageFile", "media"].includes(sourceType);
    }

    toImportableData() {
        // has the following form:  { type: '<base64|text}|url|etc>', mimetype: '<mimetype>', data: <data-encoding-as-type-specifies> }
        return this.value;
    }
}

export class ColorValue extends DataValue {
    constructor(value, locked, label) {
        const val = value === "randomPastel" ? getRandomPastelColor() : value;
        super("color", val, locked, label);
    }
}

import { create } from "zustand";
import { produce } from "immer";
import cloneDeep from "lodash.clonedeep";

const initStore = {
    project: {
        _id: localStorage.getItem("project._id"),
        name: localStorage.getItem("project.name"),
        org: localStorage.getItem("project.org"),
    },
};

export const useProjectStore = create((set, get) => ({
    ...cloneDeep(initStore),

    methods: {
        setProject: project => {
            set(
                produce(state => {
                    state.project = project;
                })
            );

            localStorage.setItem("project._id", project._id);
            localStorage.setItem("project.name", project.name);
            localStorage.setItem("project.org", project.org);
        },

        clearProject: () => {
            set(
                produce(state => {
                    state.project = {
                        _id: null,
                        name: null,
                        org: null,
                    };
                })
            );

            localStorage.removeItem("project._id");
            localStorage.removeItem("project.name");
            localStorage.removeItem("project.org");
        },
    },
}));

// the main logic engine for the client-side hyperflow workspace
//   - handles all the interaction with the back-end flow-graph runner via a websocket
//   - monitors & manages state changes in hyperflow state store
import ReconnectingWebSocket from "reconnecting-websocket";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuidv4 } from "uuid";

import { flowgraphStoreSubscribe, useFlowGraphStore } from "@mirinae/hyperflow/modules/stores/flowgraph";
import { apiPaths, wsPaths } from "@mirinae/defines/paths";
import { httpAPI } from "@mirinae/apis/http";
import { getByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { useDirectoryStore } from "@mirinae/hyperflow/modules/stores/directory";
import { useViewerStore } from "@mirinae/hyperflow/modules/stores/viewer";
import { useChatbotStore, useChatbotStoreMethods } from "@hyperflow/modules/stores/chatbot";
import { DataValue, ServiceValue } from "@mirinae/classes/DataValue";
import { useTagTreeStore, useTagTreeStoreMethods } from "@hyperflow/modules/stores/tagTree";
import { useProjectStore } from "@hyperflow/modules/stores/project";

// a singleton of the following controls local flowgraph execution flow and handles message-based RPC over
// websockets with the front-end-api server
class FlowGraphEngine {
    constructor() {
        this.runningFlowGraph = null;
        this.flowGraphWebsocket = null;
        this.initializing = false;
        this.connecting = false;
        this.clientID = uuidv4();
        console.log("constructing flowgraph engine");
    }

    async initialize() {
        // initialize the flow-graph engine, loading-out the flow-graph state store from the libraries, h-tag directories, etc.
        const {
            methods: { setTools },
        } = useFlowGraphStore.getState();
        const {
            methods: { reloadTagTree },
        } = useTagTreeStore.getState();
        const { project } = useProjectStore.getState();

        if (!this.flowGraphWebsocket && !this.initializing) {
            this.initializing = true;
            console.log(" flowgraph engine intializing...", this.clientID);

            const tools = await httpAPI("", apiPaths.getToolList, { method: "GET" });
            if (tools.data) {
                const { proformaFlowGraph, adapters, nodeClasses } = tools.data;
                proformaFlowGraph.type = "flow-graph";
                delete proformaFlowGraph._id;
                delete proformaFlowGraph.created;
                delete proformaFlowGraph.updated;
                setTools({
                    proformaFlowGraph,
                    adapters,
                    nodeClasses,
                });
            }

            // load a copy of the tagging tree for the various directories and menus that use it
            reloadTagTree(project._id);

            // open websocket to flowgraph controller in the API server, ensure we get connected & hang onto the socket
            return new Promise((resolve, reject) => {
                if (!this.connecting) {
                    console.log("connect to flow-graph websocket", this.clientID);
                    this.connecting = true;
                    const rws = new ReconnectingWebSocket(wsPaths.connect, [], {
                        // 5556', [], {   // wsPaths.connect
                        reconnectInterval: 3000, // Reconnect after 3 seconds
                        reconnectDecay: 1.5, // Increase the interval by 50% each time
                        reconnectAttempts: 10, // Limit the number of reconnection attempts
                        maxReconnectInterval: 30000, // Cap the interval at 30 seconds
                        debug: false,
                    });

                    // handle socket events
                    rws.onopen = () => {
                        this.connecting = false;
                        console.log("flow-graph websocket opened", this.clientID);
                        rws.send(JSON.stringify({ message: "hello", clientID: this.clientID }));

                        // prepare to respond to messages from the server-side flowGraph engine
                        rws.onmessage = event => {
                            console.log("Received message from server-side flowGraph engine:", event.data);
                            if (event.data === "connected") {
                                console.log("flow-graph websocket connected");
                                this.flowGraphWebsocket = rws;
                                this.initializing = false;
                                resolve();
                            } else {
                                this.handleWebsocketMessage(event);
                            }
                        };
                    };

                    // handle any async errors
                    rws.onError = event => {
                        if (!this.flowGraphWebsocket) {
                            console.log("websocket.onError", event.error);
                            // failed connect
                            reject(event.error);
                        } else {
                            runError(`Flow graph websocket asynchronous error: ${event.error}`);
                        }
                    };
                }
            });
        }
    }

    async sendWebsocketMessage(msg, stepContext, callback = () => {}) {
        // send message to backend over the websocket
        // stepContext: { runState, flowGuid, nodeID, sessionStepID, currentStepIndex };
        const {
            flow: { steps },
            methods: { setBusy },
        } = useFlowGraphStore.getState();
        setBusy(true);
        const parameters = steps[stepContext.currentStepIndex].parameters;
        stepContext.projectID = useProjectStore.getState().project._id;
        console.log("sending msg", msg.message, this.clientID);
        return this.flowGraphWebsocket.send(
            JSON.stringify({
                stepContext,
                parameters,
                ...msg,
                clientID: this.clientID,
            }),
            callback
        );
    }

    async handleWebsocketMessage(event) {
        // handles responses from the server-side flow-engine over the websocket, usually in response to a request from this side

        const {
            flow: { runState, flowGraph, steps },
            methods: {
                // hey!! maybe factor this into the handlers that need particular methods
                setSession,
                setSessionStep,
                runError,
                setGeneration,
                setBusy,
                copyStepParamsToNode,
                addStepDisplay,
                setStepMetadata,
                newSessionStep,
                setParameter,
                setFlowRunState,
                setStepException,
                setNodeReady,
                setStepDisplay,
                updateStepNodeID,
                getParameter,
                setStepUserMessage,
                completeSessionStep,
                setConfigurationPreview,
                setNextStep,
                setBusyStatus,
                setGatheringParameters,
            },
        } = useFlowGraphStore.getState();
        const {
            methods: { reloadTagTree },
        } = useTagTreeStore.getState();
        const { project } = useProjectStore.getState();

        if (!flowGraph) {
            // flow stopped
            setBusy(false);
            return;
        }

        const msg = JSON.parse(event.data);
        const { message, stepContext, error } = msg;

        if (error) {
            runError(`Error from server-side flowGraph engine: ${error}`);
            setBusy(false);
            return;
        }

        const { nodeID, sessionStepID, currentStepIndex } = stepContext;
        const node = flowGraph.nodes[nodeID];
        if (!node) {
            return; // ignore msgs for deleted nodes, server-side engine catching up with client-side flow-graph state
        }

        console.log("received msg", message, msg);
        console.log("steps", steps);

        setSessionStep(sessionStepID, nodeID); // ?? needed?

        switch (message) {
            case "node.parameters.request": {
                // asking for runtime parameter entry
                setBusy(false);
                const { nodeParameters } = msg;
                if (nodeParameters) {
                    // add any supplied additional parameters from the server-side node code
                    for (const paramPath in nodeParameters) {
                        setParameter(paramPath, DataValue.makeFrom(nodeParameters[paramPath]));
                    }
                }
                setGatheringParameters(true);
                const parameterSpecs = msg.parameterSpecs || node.parameterSpecs;
                await this.getParametersFromSpecs(parameterSpecs, node);
                const {
                    flow: { steps, runState: currentRunState },
                } = useFlowGraphStore.getState();

                // all node-parameter input is complete, decide whether to request a final OK button,
                // currently if any actual user input occured during the parameter phase
                if (!runState.startsWith("configure") && steps[currentStepIndex].hadParameterInput) {
                    const okButtonSpecs = [{ type: "runButton", label: "OK", pathName: "__runButton" }];

                    // wait for a rumButton or someone else triggering the run with triggerRunButton
                    const runButtonTrigger = new Promise(resolve =>
                        flowgraphStoreSubscribe(state => state.flow.steps[currentStepIndex].runButtonTrigger, resolve)
                    );
                    const allParameters = this.getParametersFromSpecs(okButtonSpecs, node);
                    await Promise.any([allParameters, runButtonTrigger]);
                }

                stepContext.runState = currentRunState;
                this.sendWebsocketMessage({ message: "node.parameters.received" }, stepContext);
                break;
            }

            case "node.preview.ready": {
                setBusy(false);
                if (msg.display) {
                    if (runState === "configuring") {
                        setConfigurationPreview(msg.display);
                    } else {
                        setStepDisplay(msg.display);
                    }
                }
                break;
            }

            case "node.ready": {
                switch (runState) {
                    case "single-step":
                    case "running": {
                        // node ready to run - run it
                        // first update flow-graph's node's parameters to those derived during the just prior prep phase
                        // & send them up to the back-end so its node's copy is up-to-date
                        copyStepParamsToNode(node.nodeID);
                        setNodeReady(node.nodeID, true);
                        setGatheringParameters(false);
                        const { parameters } = steps[stepContext.currentStepIndex];
                        this.sendWebsocketMessage({ message: "node.run", parameters }, stepContext);
                        break;
                    }

                    case "configuring": {
                        // in configure mode which piggybacks on the node-prep segment of the run flow
                        // save any parameter settings for the node, tidy up temp session records, and drop out of the normal run cycle
                        copyStepParamsToNode(node.nodeID);
                        // hey!! add an API call to drop temp Session & SessionStep - msg.sessionStepID !!
                        setBusy(false);
                        break;
                    }

                    case "running-resetting":
                        setBusy(false);
                        break;

                    default: {
                        setSessionStep(null, null); // asynch node.ready after the flow has quiesced, undo the sessionStep setting
                        break;
                    }
                }
                break;
            }

            // this (may be) IS deprecated and must not be used, new parameter input scheme depends on "node.parameters.request" being the
            //  only node-originating call for parameter input, use a "serviceParameterSpecs" parameter type in the node's parameterSpecs
            case "service.parameters.request": {
                setBusy(false);
                return;
                // // asking for a mid-run parameter entry from one of the services
                // setBusy(false);
                // const { serviceContext } = msg;
                // if (serviceContext) {
                //     // add any supplied additional parameters from the service
                //     for (const paramPath in serviceContext) {
                //         setParameter(paramPath, DataValue.makeFrom(serviceContext[paramPath]));
                //         setParameter(paramPath, DataValue.makeFrom(serviceContext[paramPath]));
                //     }
                // }
                // const service = findService(msg.service);
                // // check for already cached service in this running flowGraph node
                // const serviceKey = [service.serviceFamily, service.serviceType];
                // let parameterSpecs = flowGraph.nodes[node.nodeID].services[serviceKey]?.parameterSpecs;
                // if (!parameterSpecs) {
                //     parameterSpecs = msg.parameterSpecs || service.parameterSpecs;
                //     setServiceParameterSpecs(node, service, parameterSpecs);
                // }
                // // get them
                // await this.getParametersFromSpecs(parameterSpecs, node);
                // this.sendWebsocketMessage({ message: "service.parameters.received", service }, stepContext);
                // break;
            }

            case "flowGraph.started":
                const { sessionID, sessionGuid } = msg;
                setSession(sessionID, sessionGuid);
                if (msg.startingNodeID) updateStepNodeID(msg.startingNodeID);
                break;

            case "flowGraph.nextStep": {
                const { metadata, completedSessionStepID } = msg;
                if (metadata) setStepMetadata(metadata);

                // record completed step's local state
                const stepUpdates = completeSessionStep();
                await httpAPI("", apiPaths.updateSessionStep, {
                    data: {
                        sessionStepID: completedSessionStepID,
                        stepUpdates,
                    },
                });

                // prep next step & ask next step's node to proceed
                newSessionStep(currentStepIndex, nodeID, flowGraph.nodes[nodeID].displayName, sessionStepID);
                if (runState === "single-step") {
                    setNextStep({ message: "node.prepare" }, stepContext);
                    setBusy(false);
                } else {
                    this.sendWebsocketMessage({ message: "node.prepare" }, stepContext);
                }
                break;
            }

            case "flowGraph.exception": {
                const { exception } = msg;
                setStepException(exception);
                const stepUpdates = completeSessionStep();
                await httpAPI("", apiPaths.updateSessionStep, {
                    data: { sessionStepID, stepUpdates },
                });
                setBusy(false);
                setFlowRunState("failed");
                break;
            }

            case "flowGraph.end": {
                const { metadata, completedSessionStepID } = msg;
                if (metadata) setStepMetadata(metadata);
                const stepUpdates = completeSessionStep();
                await httpAPI("", apiPaths.updateSessionStep, {
                    data: {
                        sessionStepID: completedSessionStepID,
                        stepUpdates,
                    },
                });
                setBusy(false);
                setFlowRunState("finished");
                break;
            }

            case "generation.busyMessage": {
                if (!runState.endsWith("resetting")) {
                    const { busyMessage } = msg;
                    const {
                        methods: { setBusyMessage },
                    } = useChatbotStore.getState();
                    setBusy(true);
                    setBusyMessage(busyMessage);
                    setBusyStatus(busyMessage.value);
                }
                break;
            }

            case "generation.response": {
                // get a response back from a generate call to an LLM service. add to the current steps output display
                setGeneration(msg.response);
                const {
                    response: { generatedText, rawGeneratedText, generatedImage, generatedVideo },
                    role,
                } = msg;
                if (rawGeneratedText) {
                    addStepDisplay({ generatedText: rawGeneratedText });
                }
                if (generatedImage) {
                    // massage into URL form
                    if (generatedImage.dataType === "base64") {
                        generatedImage.data = `data:image/png;base64,${generatedImage.data}`;
                        generatedImage.dataType = "url";
                        addStepDisplay({ generatedImage });
                    } else if (generatedImage.dataType === "gridfs") {
                        generatedImage.data = `/api/hyperflow/file/get/image/${generatedImage.data}`;
                        generatedImage.dataType = "url";
                        addStepDisplay({ generatedImage });
                    } else if (generatedImage.dataType === "url") {
                        addStepDisplay({ generatedImage });
                    }
                }
                if (generatedVideo) {
                    if (generatedVideo.dataType === "base64") {
                        const fileID = await saveData({
                            dataType: "base64",
                            mimetype: generatedVideo.mimetype,
                            data: generatedVideo.data,
                            metadata: {},
                        });
                        generatedVideo.data = `/api/hyperflow/file/get/video/${fileID}`;
                    } else if (generatedVideo.dataType === "gridfs") {
                        generatedVideo.data = `/api/hyperflow/file/get/video/${generatedVideo.data}`;
                    }
                    generatedVideo.dataType = "url";
                    addStepDisplay({ generatedVideo });
                }
                setBusy(false);
                // send generated text to chatbot if open & not a secondary generator
                const {
                    bot: { showChatbot },
                    methods: { addToChat },
                } = useChatbotStore.getState();
                const hideGeneratedText = getByPath(node.parameters, "hideGeneratedText")?.value;
                if (!hideGeneratedText) {
                    if (showChatbot && !["instruct", "classify", "rerank", "image"].includes(role)) {
                        addToChat({ type: "generator", text: generatedText });
                    }
                }
                if (showChatbot && !["instruct", "classify", "rerank", "generator"].includes(role)) {
                    addToChat({ type: "image", image: generatedImage });
                }
                break;
            }

            case "generation.referencedSegments": {
                const { referencedSegments } = msg;
                const {
                    bot: { showChatbot },
                    methods: { addToChat },
                } = useChatbotStore.getState();

                // merge & sort refs
                const references = []; // Data structure to hold the result
                {
                    const dedup = new Set();

                    for (const seg of referencedSegments.value.segments) {
                        let image, link;
                        let pageNumber = "";
                        let documentName = "";

                        // Extract metadata values
                        const metadata = seg.metadata.reduce((acc, md) => {
                            acc[md.key] = md.value;
                            return acc;
                        }, {});

                        const mimetype = metadata["mimetype"];
                        const file = metadata["file"];
                        const url = metadata["url"];
                        const title = metadata["title"];

                        if (metadata["document"]) {
                            documentName = metadata["document"];
                        }
                        if (metadata["page"]) {
                            pageNumber = metadata["page"];
                        }

                        let document = references.find(doc => doc.document === documentName);
                        if (!document) {
                            document = { document: documentName, pages: [] };
                            references.push(document);
                        }

                        let page = document.pages.find(p => p.page === pageNumber);
                        if (!page) {
                            page = { page: pageNumber, images: [], links: [] };
                            document.pages.push(page);
                        }
                        if (mimetype?.startsWith("image") && file) {
                            const imgURL = `/api/hyperflow/file/get/image/${file}`;
                            if (!dedup.has(imgURL)) {
                                image = { dataType: "url", mimetype, data: imgURL };
                                dedup.add(imgURL);
                                page.images.push(image);
                            }
                        }
                        if (url) {
                            if (!dedup.has(url)) {
                                link = { title, url };
                                dedup.add(url);
                                page.links.push(link);
                            }
                        }
                    }
                }

                addStepDisplay({ references });
                if (showChatbot) addToChat({ type: "references", references });
                break;
            }

            // this likely depracated; now the prompt-button nodes provide any buttons to the prompt.button input on the getPrompt node
            case "prompts.offer": {
                const { promptButtons } = msg;
                // for now, a modest hack, pressing the parameter I/O system into play for this, consing up a mock parameterSpec
                //  maybe the matchAndButtons node should only output prompts that can be inputs to the getPrompt node??
                setBusy(false);
                const paramSpecs = [{ type: "prompt", pathName: "prompt.text", promptButtons }];
                await this.getParametersFromSpecs(paramSpecs, node);
                const promptText = getParameter("prompt.text");
                this.sendWebsocketMessage({ message: "node.prompt.received", promptText }, stepContext);
                break;
            }

            case "user.message": {
                const { userMessage } = msg;
                setStepUserMessage(userMessage);
                setBusy(false);
                const {
                    bot: { showChatbot },
                    methods: { addToChat },
                } = useChatbotStore.getState();
                if (showChatbot) {
                    addToChat({ type: "message", text: userMessage });
                }
                break;
            }

            case "directory.reload":
                reloadTagTree(project._id);
                break;

            case "stepDisplay.add":
                addStepDisplay(msg.display);
                break;

            case "stepDisplay.set":
                setStepDisplay(msg.display);
                break;

            case "stepDisplay.updates":
                console.log(msg.update);
                break;

            case "busyStatus.update":
                setBusyStatus(msg.update);
                break;

            default:
                runError(`Unrecognized message from server-side flowGraph engine: ${msg.error}`);
                setBusy(false);
                break;
        }
    }

    async getParametersFromSpecs(parameterSpecs, node, service) {
        // takes a list of specs defining the way to get parameters for the requesting node or service and gets them.
        // the initiControllingParameter, if supplied, is the pathname of the parameter that caused this set of specs to
        // be requested, used for dependency-graph building.
        const {
            bot: { showChatbot },
            methods: { setChatPromptParamUI },
        } = useChatbotStore.getState();
        const paramInputPromises = [];
        const serviceGuid = service?.guid;

        const {
            methods: {
                findService,
                addControlledSpec,
                setControllingParameter,
                setInputs,
                setOutputs,
                setParamUILoading,
                setStepException,
            },
        } = useFlowGraphStore.getState();

        const activeSpecs = specs => specs.filter(spec => !["okButton", "newColumn", "startColumn", "endColumn"].includes(spec.type)); // since new paramStack UI

        for (const paramSpec of activeSpecs(parameterSpecs)) {
            const getParameter = async (paramUI, origParameter) => {
                // // always get latest state
                // const {
                //     flow: { runState, steps, currentStepIndex },
                //     methods: { setParameter, addUIForParameter, endParameterResetting, showParameterHistory, noteHadParameterInput },
                // } = useFlowGraphStore.getState();

                return new Promise(async (resolve, reject) => {
                    // obtains a single parameter value, either from a preset or locked value, entered in an earlier loop, or by adding UI to ask for it

                    // always get latest state
                    const {
                        flow: { runState, steps, currentStepIndex },
                        methods: { setParameter, addUIForParameter, endParameterResetting, showParameterHistory, noteHadParameterInput },
                    } = useFlowGraphStore.getState();

                    if (["startColumn", "endColumn", "newColumn"].includes(paramUI.type)) {
                        // mark a sequence of parameter UI elements layed out in column
                        addUIForParameter({ type: paramUI.type, locked: true });
                        return resolve();
                    }
                    if ((showChatbot && paramUI.type === "previewButton") || paramUI.type === "okButton") {
                        return resolve();
                    }
                    let parameter = origParameter;

                    paramUI.node = node;
                    paramUI.awaiting = false;
                    const paramInput = node.inputs.find(ip => ip.source === "parameter" && ip.pathName === paramUI.pathName);
                    paramUI.paramInputIsWired = paramInput && paramInput.edgeID !== null;

                    if (parameter?.value === undefined && paramUI.defaultValue?.locked) {
                        // if as-yet undefined & supplied default says locked, pick up the default & lock
                        parameter = DataValue.makeFrom(paramUI.defaultValue);
                        setParameter(paramUI.pathName, parameter);
                    }

                    const paramIsDefined =
                        parameter !== undefined &&
                        parameter.value !== undefined &&
                        parameter.value !== null &&
                        paramUI.alwaysWait !== true &&
                        !paramUI.type.match(/runButton|branchChoice/);
                    const paramIsLocked = paramIsDefined && parameter.locked;
                    const paramIsResetting = paramIsDefined && parameter?.resetting && runState.endsWith("-resetting");

                    if (runState === "running" && showChatbot && paramUI.type === "prompt") {
                        // for now, divert prompt input to the test chatbot, continue to schedule wait for prompt if unlocked, test-bot will resolve
                        paramUI.parameter = parameter;
                        setChatPromptParamUI(paramUI);
                        showParameterHistory(paramUI);
                        if (!paramIsLocked && !paramIsResetting) {
                            addUIForParameter(paramUI);
                            flowgraphStoreSubscribe(
                                state => getByPath(state.flow.steps[currentStepIndex].parameters, paramUI.pathName),
                                resolve
                            );
                            return;
                        }
                    }

                    if ((paramIsDefined || paramUI.paramInputIsWired) && !paramIsResetting) {
                        // parameter value already set or data-flow wired, short-circuit interaction
                        if (paramIsDefined && !(parameter instanceof DataValue)) {
                            parameter = DataValue.makeFrom(parameter);
                            setParameter(paramUI.pathName, parameter);
                        }
                        noteHadParameterInput(!paramIsLocked);
                        paramUI.closed = paramIsLocked;
                        paramUI.locked = paramIsLocked;
                        if (!paramUI.paramInputIsWired) addUIForParameter(paramUI);
                        resolve(parameter);
                    } else {
                        addUIForParameter(paramUI);
                        noteHadParameterInput(true);
                        if (runState.match(/-resetting$/)) {
                            // in resetting phase, pass by all input waits until we hit the resetting parameter and restore normal state flow
                            if (paramIsResetting) {
                                endParameterResetting(paramUI);
                                // if it doesn't have a value already, wait for user to enter parameter (resolve will be called when entered)
                                if (parameter.value === undefined) {
                                    flowgraphStoreSubscribe(
                                        state => getByPath(state.flow.steps[currentStepIndex].parameters, paramUI.pathName),
                                        resolve
                                    );
                                    paramUI.awaiting = true;
                                } else {
                                    resolve(parameter.value);
                                }
                            } else {
                                resolve(parameter);
                            }
                        } else if (
                            paramUI.noWait ||
                            paramUI.defaultValue ||
                            (paramUI.configureOnly && !runState.startsWith("configuring"))
                        ) {
                            // set default if parameter undefined & noWait
                            const updateValue =
                                parameter?.value === undefined && paramUI.defaultValue
                                    ? paramUI.defaultValue
                                    : parameter instanceof DataValue
                                      ? null
                                      : parameter;
                            if (updateValue) {
                                setParameter(paramUI.pathName, DataValue.makeFrom(updateValue));
                            }
                            resolve(parameter?.value);
                        } else if (paramUI.type === "prompt" && !showChatbot) {
                            // hey!! the following are hacks for the new parameter-stack scheme, implied nowWait now but the main OK button should only enable if input not empty!
                            // hey!! generalize this via new param declarations
                            resolve(parameter?.value);
                        } else if (paramUI.type === "instructions") {
                            resolve(parameter?.value);
                        } else if (paramUI.type === "previewButton") {
                            resolve(parameter?.value);
                        } else {
                            // wait for user to enter parameter?.value,
                            flowgraphStoreSubscribe(
                                state => getByPath(state.flow.steps[currentStepIndex].parameters, paramUI.pathName),
                                resolve
                            );
                            paramUI.awaiting = true;
                        }
                    }
                    showParameterHistory(paramUI);
                });
            };

            // always get latest state
            const {
                flow: { steps, currentStepIndex },
                methods: { setParameter, addUIForParameter },
            } = useFlowGraphStore.getState();

            switch (paramSpec.type) {
                // hey!! make the following to grouping constructs recurse into getParametersFromSpecs to allow embedded conditionals
                case "group": {
                    // specs can be grouped for simultaneous input, with the last parameter in the group waited on,
                    // so it needs to have a distinct action & parameter setting associated, use 'ok' type if necessary
                    // this is a leaf construct, there can be no nested groups or switches, etc.
                    let inputPromise;
                    for (const spec of activeSpecs(paramSpec.specs)) {
                        const parameter = spec.pathName && getByPath(steps[currentStepIndex].parameters, spec.pathName);
                        const paramUI = { ...spec, parameter, serviceGuid };
                        addControlledSpec({ paramSpec: spec, paramUI });
                        inputPromise = getParameter(paramUI, parameter);
                        if (spec.controllingParameter) {
                            setControllingParameter(spec.pathName);
                        }
                    }
                    if (!paramSpec.noWait && inputPromise) {
                        // wait on last param input
                        await inputPromise;
                    }
                    break;
                }

                case "collapsingGroup": {
                    addUIForParameter({ ...paramSpec, locked: true });
                    await this.getParametersFromSpecs(paramSpec.specs, node, service);
                    addUIForParameter({ type: "endCollapsingGroup", locked: true });
                    break;
                }

                case "if": {
                    // test parameter, select then: or else: paramspec
                    const testsParameter = getByPath(steps[currentStepIndex].parameters, paramSpec.test);

                    // cons a paramSpec & paramUI for this test so it can be involved in the controlling-parameter dependency tree
                    paramSpec.pathName = paramSpec.test;
                    const paramUI = {
                        ...paramSpec,
                        parameter: testsParameter,
                        serviceGuid,
                    };
                    addControlledSpec({ paramSpec, paramUI });

                    setControllingParameter(paramSpec.test); // if test param is always controlling (right?)

                    const testValue = testsParameter?.value;
                    const branchSpecs = testValue && paramSpec[testValue ? "then" : "else"];
                    if (branchSpecs) {
                        await this.getParametersFromSpecs(branchSpecs, node, service);
                    }
                    break;
                }

                case "switch": {
                    // switch between paramSpec lists based on a case parameter value
                    const switchParameter = getByPath(steps[currentStepIndex].parameters, paramSpec.on);

                    // cons a paramSpec & paramUI for this switch so it can be involved in the controlling-parameter dependency tree
                    paramSpec.pathName = paramSpec.on;
                    const paramUI = {
                        ...paramSpec,
                        parameter: switchParameter,
                        serviceGuid,
                    };
                    addControlledSpec({ paramSpec, paramUI });

                    setControllingParameter(paramSpec.on); // switch on param is always controlling (right?)

                    const switchValue = switchParameter?.value;
                    // support "or" cases, "case1|case2|case3": [...]
                    const caseSpecs =
                        switchValue &&
                        Object.entries(paramSpec.cases).find(([caseLabel, caseSpecs]) => caseLabel.split("|").includes(switchValue))?.[1];
                    const defaultSpecs = paramSpec.cases["default"];
                    if (caseSpecs) {
                        if (caseSpecs?.length > 0) {
                            await this.getParametersFromSpecs(caseSpecs, node, service);
                        }
                    } else if (defaultSpecs?.length > 0) {
                        await this.getParametersFromSpecs(defaultSpecs, node, service);
                    }
                    break;
                }

                case "service": {
                    let service = getByPath(steps[currentStepIndex].parameters, paramSpec.pathName);
                    if (service?.value && !service?.value?.displayName) {
                        // fill out any preset services with all service metadata
                        service = new ServiceValue(findService(service.value), service.locked);
                        setParameter(paramSpec.pathName, service);
                    }
                    const paramUI = { ...paramSpec, parameter: service, serviceGuid };
                    addControlledSpec({ paramSpec, paramUI });
                    if (paramSpec.controllingParameter) {
                        setControllingParameter(paramSpec.pathName);
                    }
                    service = await getParameter(paramUI, service);
                    // check for any service-defined input/output ports & add if not present
                    const missingInputs = service.value?.inputs?.filter(sip => !node.inputs.find(nip => nip.pathName === sip.pathName));
                    if (missingInputs?.length > 0) {
                        const updateInputs = node.inputs.concat(missingInputs);
                        setInputs(node.nodeID, updateInputs);
                    }
                    const missingOutputs = service.value?.outputs?.filter(sop => !node.outputs.find(nop => nop.pathName === sop.pathName));
                    if (missingOutputs?.length > 0) {
                        const updateOutputs = node.outputs.concat(missingOutputs);
                        setOutputs(node.nodeID, updateOutputs);
                    }
                    break;
                }

                case "serviceParameterSpecs": {
                    // the paramSpec names the parameter containing the service-specifying value (now paramSpec.serviceParameter), eg, the vectorDB
                    setParamUILoading(true);
                    // first ask the service for any runtime context parameters values needed for the reset of the service's paramSpec interactions
                    // such as model lists for an LLM service, import formats for an importer, etc.
                    const { parameters, stepContext } = steps[currentStepIndex];
                    const serviceParameter = getByPath(parameters, paramSpec.serviceParameter);
                    let service = serviceParameter?.relatedService || serviceParameter?.value;
                    console.log("service", service);
                    if (service) {
                        let { type, serviceType, serviceFamily } = service;
                        serviceType = paramSpec.usesServiceType || serviceType; // allows the paramSpec to switch types for the service family (eg, vectorDB search adapter for the same family that created the DB)
                        service = findService({ type, serviceType, serviceFamily });
                        const response = await httpAPI("", apiPaths.getServiceContext, {
                            data: {
                                service: { type, serviceType, serviceFamily },
                                parameters,
                                stepContext,
                            },
                        });
                        const {
                            data: { exception },
                        } = response;
                        if (exception) {
                            setStepException(exception);
                            setParameter(paramSpec.serviceParameter, new ServiceValue(undefined, false));
                            break;
                        }
                        const { data: serviceContext } = response;
                        if (serviceContext) {
                            // add any supplied additional parameters from the service
                            for (const paramPath in serviceContext) {
                                setParameter(paramPath, DataValue.makeFrom(serviceContext[paramPath]));
                            }
                        }
                        // // cons a paramSpec & paramUI for this so it can be involved in the controlling-parameter dependency tree
                        // paramSpec.pathName = paramSpec.serviceParameter;
                        // const paramUI = { ...paramSpec, parameter: serviceParameter, serviceGuid: service.guid };
                        // addControlledSpec({ paramSpec, paramUI });
                        // if (paramSpec.controllingParameter) {
                        //     setControllingParameter(paramSpec.serviceParameter);
                        // }

                        // grab the specs from the adapter's definition & interact with user
                        await this.getParametersFromSpecs(service.parameterSpecs, node, service);

                        setParamUILoading(false);
                    }
                    break;
                }

                case "previewButton": {
                    const paramUI = { ...paramSpec, pathName: "__preview" }; // dummy parameter name
                    await getParameter(paramUI);
                    break;
                }

                default: {
                    if (paramSpec.noUI) break;
                    const parameter = paramSpec.pathName && getByPath(steps[currentStepIndex].parameters, paramSpec.pathName);
                    const paramUI = { ...paramSpec, parameter, serviceGuid };
                    addControlledSpec({ paramSpec, paramUI });
                    if (paramSpec.controllingParameter) {
                        setControllingParameter(paramSpec.pathName);
                    }
                    await getParameter(paramUI, parameter);
                    break;
                }
            }
        }

        // now wait for the rest of the requested parameters to be input
        if (paramInputPromises.length > 0) await Promise.all(paramInputPromises);

        // fill in defaults if needed
        this.loadParamDefaults();
    }

    async selectFlowGraphTemplate(flowGraphTemplate, status = "creating") {
        // flow-graph for this session selected, record it, ask it for required paramters for starting node & trigger UI to get them
        const {
            methods: { setFlowGraph },
        } = useFlowGraphStore.getState();
        const {
            methods: { setFocusedNode },
        } = useViewerStore.getState();

        // assign new flow for this run, reset to setup step
        let flowGraph = this.cleanedFlowGraphCopy(flowGraphTemplate);
        flowGraph.status = status;
        flowGraph.tags = "";
        const { project } = useProjectStore.getState();
        const response = await httpAPI("", apiPaths.saveFlowGraph, {
            data: { flowGraph, projectID: project._id },
        });
        flowGraph = response.data.flowGraph;
        setFocusedNode(null);
        setFlowGraph(flowGraph);
        return flowGraph;
    }

    async startFlowGraph(flowGraph, options = { singleStep: false }) {
        // initialize and start flow-graph run.  If debug mode, enter single-step
        const {
            methods: { runStarting, setNextStep, setStepContext },
        } = useFlowGraphStore.getState();
        const {
            methods: { clearChathistory },
        } = useChatbotStore.getState();

        const flowGuid = flowGraph.guid;

        if (flowGraph.startNodeID in flowGraph.nodes) {
            runStarting(flowGraph, options);
            this.runningFlowGraph = flowGraph;

            const stepContext = {
                runState: "running",
                flowGuid,
                nodeID: flowGraph.startNodeID,
                currentStepIndex: 1,
                nodeCallStack: [],
            }; // starting stepContext
            setStepContext(stepContext);

            const message = { message: "flowGraph.start" };

            if (options.singleStep) {
                // if single-stepping, record next-step state
                setNextStep(message, stepContext);
            } else {
                // otherwise start it
                this.sendWebsocketMessage(message, stepContext);
            }
        }
    }

    async singleStep() {
        // single-step current flow by issuing the stored nextStep bundle
        const {
            flow: {
                nextStep: { message, stepContext },
            },
            methods: { setNextStep },
        } = useFlowGraphStore.getState();
        if (stepContext) {
            setNextStep(null, null);
            this.sendWebsocketMessage(message, stepContext);
        }
    }

    async configureNodeParameters(node) {
        // displays & allows setting of selected node parameters (for viewing & presetting)
        const {
            flow: {
                runState,
                flowGraph: { guid: flowGuid, type, status },
            },
            methods: { configureNode, copyStepParamsToNode, setFlowRunState },
        } = useFlowGraphStore.getState();

        // prepare configure state & ask node to run through it's prepare cycle, commandeering step 0
        configureNode(node);
        const stepContext = {
            runState: "configuring",
            flowGuid,
            nodeID: node.nodeID,
            currentStepIndex: 0,
        };
        this.sendWebsocketMessage({ message: "node.prepare" }, stepContext);
        // wait for the active node in the graph-viewer to be cleared and copy any set parameters to the original node
        const focusedNode = await new Promise((resolve, reject) => {
            const rpUnsubscribe = useViewerStore.subscribe(
                state => state.viewer.focusedNode,
                focusedNode => {
                    rpUnsubscribe();
                    resolve(focusedNode);
                }
            );
        });

        // grab changes in step 0 params, etc., into node & save updated flow
        const {
            flow: { flowGraph },
        } = useFlowGraphStore.getState();

        if (flowGraph?.nodes[node.nodeID]) {
            // if node still exists (may have been deleted)
            copyStepParamsToNode(node.nodeID, { clearCurrentNode: true });
            if (flowGraph.type !== "built-in-flow-graph" && flowGraph.status.match(/editing|creating|test/)) {
                console.log("save flowgraph", flowGraph.nodes);
                const { project } = useProjectStore.getState();
                await httpAPI("", apiPaths.saveFlowGraph, {
                    data: { flowGraph, projectID: project._id },
                });
            }

            // if new node selected while not running, launch its config
            if (focusedNode) {
                if (runState !== "running") this.configureNodeParameters(focusedNode);
            } else {
                setFlowRunState("loaded");
            }
        } else {
            setFlowRunState("loaded");
        }
    }

    async resetParameter(resettingParameterName) {
        // a locked parameter is unlocked or an earlier-set parameter is changed by the user, mark the parameter as 'resetting', reset the parameter input state
        // and re-start the node.preparation in 'configuring-resetting' or 'run-resetting' flow state to re-establish parameter
        // input state up to the 'resetting' parameter & unlock it for input
        const {
            methods: { startParameterResetting, copyStepParamsToNode, setFlowRunState },
        } = useFlowGraphStore.getState();
        startParameterResetting(resettingParameterName);
        const { flow } = useFlowGraphStore.getState();
        const {
            runState,
            flowGraph: { guid: flowGuid },
            steps,
            currentStepIndex,
        } = flow;
        const { nodeID } = steps[currentStepIndex];

        const stepContext = {
            runState,
            flowGuid,
            nodeID,
            sessionStepID: steps[currentStepIndex].sessionStepID,
            currentStepIndex,
        };
        this.sendWebsocketMessage({ message: "node.prepare" }, stepContext);

        if (runState === "configuring") {
            // if we were in the configure state, effectively resume it
            // wait for the active node in the graph-viewer to be cleared and copy any set parameters to the original node
            const focusedNode = await new Promise((resolve, reject) => {
                const rpUnsubscribe = useViewerStore.subscribe(
                    state => state.viewer.focusedNode,
                    focusedNode => {
                        rpUnsubscribe();
                        resolve(focusedNode);
                    }
                );
            });
            copyStepParamsToNode(nodeID);
            if (!focusedNode) {
                setFlowRunState("loaded");
            }
        }
    }

    loadParamDefaults() {
        // fill in defaults if needed, checking latest store state
        const {
            flow: upToDateFlow,
            methods: { setParameter, wrapParameter },
        } = useFlowGraphStore.getState();
        const currentStep = upToDateFlow.steps[upToDateFlow.currentStepIndex];
        const { parameters, parameterUI } = currentStep;
        for (const paramUI of parameterUI) {
            if (paramUI.pathName && paramUI.type !== "branchChoice" && paramUI.type !== "previewButton") {
                const parameter = getByPath(parameters, paramUI.pathName);
                const updateValue = parameter?.value === undefined && paramUI.defaultValue && paramUI.defaultValue;
                if (updateValue) {
                    setParameter(paramUI.pathName, DataValue.makeFrom(updateValue));
                } else if (!(parameter instanceof DataValue) && parameter && "value" in parameter && "type" in parameter) {
                    wrapParameter(paramUI.pathName, parameter);
                }
            }
        }
    }

    runPreview() {
        // run current node in preview mode=
        const {
            flow: {
                runState,
                flowGraph: { guid: flowGuid },
                currentStepIndex,
                steps,
                currentNodeID: nodeID,
            },
            methods: { setBusy },
        } = useFlowGraphStore.getState();

        // celar any param UI waits, load up any default param values and ask node for a preview
        // flowgraphStoreUnSubscribeAll();
        this.loadParamDefaults();
        setBusy(true);
        const stepContext = {
            runState,
            flowGuid,
            nodeID,
            sessionStepID: steps[currentStepIndex].sessionStepID,
            currentStepIndex,
        };
        this.sendWebsocketMessage({ message: "node.preview" }, stepContext);
    }

    stopFlowGraph() {
        // not used presently
        if (this.runningFlowGraph) {
            const stepContext = { flowGuid: this.runningFlowGraph.guid };
            this.sendWebsocketMessage({ message: "flowGraph.stop" }, stepContext, () => {
                this.runningFlowGraph = null;
                this.flowGraphWebsocket.close();
                this.runningFlowGraph = null;
            });
        }
    }

    rerunLastStep() {
        if (this.runningFlowGraph) {
            const {
                flow: {
                    flowGraph: { guid: flowGuid },
                    steps,
                    currentStepIndex,
                    currentNodeID: nodeID,
                },
                methods: { setFlowRunState },
            } = useFlowGraphStore.getState();
            setFlowRunState("running");
            const stepContext = {
                runState: "running",
                flowGuid,
                nodeID,
                sessionStepID: steps[currentStepIndex].sessionStepID,
                currentStepIndex,
            };
            this.sendWebsocketMessage({ message: "flowGraph.rerunLastStep" }, stepContext);
        }
    }

    cleanedFlowGraphCopy(fg) {
        const flowGraph = cloneDeep(fg);
        delete flowGraph._id;
        delete flowGraph.signature;
        flowGraph.guid = "";
        flowGraph.type = "flow-graph";
        delete flowGraph.updated;
        delete flowGraph.created;
        Object.values(flowGraph.nodes).forEach(n => {
            n.services = {};
            Object.values(n.parameters).forEach(p => {
                if (p.locked) {
                    if (p.type === "service") {
                        const { type, serviceType, serviceFamily } = p.value;
                        p.value = { type, serviceType, serviceFamily };
                    }
                } else {
                    // p.value = undefined;  not for now
                }
            });
        });
        return flowGraph;
    }

    async exportFlowGraph(flowGraph) {
        const cleanedFlowGraph = this.cleanedFlowGraphCopy(flowGraph);
        cleanedFlowGraph.signature = "!!hyperflow-flowgraph!!"; // hey!, add a proper PKI signture to this
        const jsonedFlowGraph = JSON.stringify(cleanedFlowGraph);
        const url = window.URL.createObjectURL(new Blob([jsonedFlowGraph]));
        const link = document.createElement("a");
        link.href = url;
        const system = window.location.origin.replace(/http(s)*:\/\//, "").replace(/:\d+/, "");
        link.setAttribute("download", `export-${flowGraph.name}.json`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async importFlowGraph() {
        return new Promise((resolve, reject) => {
            const inputForm = document.getElementById("json-import-form");
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "text/json");
            inputForm.appendChild(input);
            const loadImport = async () => {
                try {
                    const json = await input.files[0].text();
                    inputForm.removeChild(input);
                    const loadedFlowGraph = JSON.parse(json);
                    if (loadedFlowGraph.signature === "!!hyperflow-flowgraph!!") {
                        const flowGraph = this.cleanedFlowGraphCopy(loadedFlowGraph);
                        flowGraph.displayName = `${flowGraph.displayName} upload`;
                        delete flowGraph._id;
                        flowGraph.guid = "";
                        delete flowGraph.signature;
                        resolve(flowGraph);
                    } else {
                        reject();
                    }
                } catch (e) {
                    // what to do with errors?
                    console.log("Upoad error", e);
                    reject();
                }
            };
            input.addEventListener("input", loadImport);
            input.click();
        });
    }
}

const flowgraph = new FlowGraphEngine(); // main client-side engine singleton

export default flowgraph;

/* eslint-disable no-underscore-dangle */

export const apiPaths = {
    getToolList: "/api/hyperflow/getToolList",
    uploadContent: "/api/hyperflow/uploadContent",
    getTagTree: "/api/hyperflow/tag/getTree",
    getVectorDB: "/api/hyperflow/vectordb/get",
    getVectorDBVisualization: "/api/hyperflow/vectordb/getVisualization",
    getTestQueryVisualization: "/api/hyperflow/vectordb/getTestQueryVisualization",
    getServiceContext: "/api/hyperflow/service/getContext",
    listSegmentationSet: "/api/hyperflow/segmentationSet/list",
    getSegments: "/api/hyperflow/segments/get",
    getPromptHistory: "/api/hyperflow/prompts/history",

    getFlowGraph: "/api/flowgraph/get", // not sure why this is in a separate path; refactor??
    saveFlowGraph: "/api/flowgraph/save",
    findFlowGraphs: "/api/flowgraph/findFlowGraphs",
    findRunSessions: "/api/flowgraph/findRunSessions",
    deleteFlowGraph: "/api/flowgraph/delete",
    getSession: "/api/flowgraph/session/get",
    updateSessionStep: "/api/flowgraph/sessionStep/update",

    // bot API
    startBot: "/api/flowgraph/bot/start",
    userPrompt: "/api/flowgraph/bot/userPrompt",
    userUpload: "/api/flowgraph/bot/userUpload",
    promptButton: "/api/flowgraph/bot/promptButton",
    getResponse: "/api/flowgraph/bot/getResponse",

    // auth API
    check: "/api/auth/check",
    signInGoogle: "/api/auth/signin/google",
    signIn: "/api/auth/signin/email",
    signUp: "/api/auth/signup",
    signOut: "/api/auth/signout",

    // admin/project API
    getOrg: "/api/admin/org",
    getOrgsForUser: "/api/admin/orgs/user",
    getProjectsForOrg: "/api/admin/org/projects",
    createOrg: "/api/admin/org/create",
    addOrgMember: "/api/admin/org/members/add",
    deleteOrgMember: "/api/admin/org/members/delete",
    createProject: "/api/admin/org/projects/create",
};

export const controlAPI = {
    startSession: "/api/flowgraph/control/start",
    progressSession: "/api/flowgraph/control/progress",
    refreshSession: "/api/flowgraph/control/refresh",
};

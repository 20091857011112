import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";
import { current, produce } from "immer";
import cloneDeep from "lodash.clonedeep";

import { getByPath, setByPath } from "@mirinae/shared/modules/utils/pathUtils";
import { useMemo } from "react";

const initStore = {
    viewer: {
        editingEnabled: false,
        focusedNode: null,
        redrawTrigger: 1,
        showDebugIDs: false,
        showDataFlow: true,
        showEdgeDragHandles: true, // false,  try always enable, invisible until hovered
        isValidHandle: true,
        highlightedEdges: [],
        width: 0,
        nodeIDsDragging: [],
        enableSubgraphSelect: false,
        selectedNodeIDs: [],
        panMode: false,
        figmaMode: true,
    },
    flowGraphForm: {
        show: false,
        flowGraph: null,
    },
};

export const useViewerStore = create(
    subscribeWithSelector((set, get) => ({
        ...cloneDeep(initStore),

        methods: {
            setEditingEnabled: (flowGraph, appMode, editingEnabled) =>
                set(
                    produce(state => {
                        state.viewer.editingEnabled =
                            appMode === "editor" &&
                            (flowGraph
                                ? editingEnabled !== undefined
                                    ? editingEnabled
                                    : flowGraph.type !== "built-in-flow-graph" && !!flowGraph.status.match(/editing|creating|test/)
                                : false);
                    })
                ),

            triggerViewerRedraw: () =>
                set(
                    produce(state => {
                        state.viewer.redrawTrigger += 1;
                    })
                ),

            setFocusedNode: node =>
                set(
                    produce(state => {
                        state.viewer.focusedNode = node;
                    })
                ),

            setIsValidHandle: validity =>
                set(
                    produce(state => {
                        state.viewer.isValidHandle = validity;
                    })
                ),

            toggleDataFlow: () =>
                set(
                    produce(state => {
                        state.viewer.showDataFlow = !state.viewer.showDataFlow;
                    })
                ),

            toggleEdgeDragHandles: () =>
                set(
                    produce(state => {
                        state.viewer.showEdgeDragHandles = !state.viewer.showEdgeDragHandles;
                    })
                ),

            toggleSubgraphSelect: () =>
                set(
                    produce(state => {
                        state.viewer.enableSubgraphSelect = !state.viewer.enableSubgraphSelect;
                    })
                ),

            setHighlightedEdges: (edgeIDs = []) =>
                set(
                    produce(state => {
                        state.viewer.highlightedEdges = edgeIDs;
                    })
                ),

            toggleDebugIDs: () =>
                set(
                    produce(state => {
                        state.viewer.showDebugIDs = !state.viewer.showDebugIDs;
                    })
                ),

            setFlowGraphForm: (show, flowGraph) =>
                set(
                    produce(state => {
                        state.flowGraphForm.show = show;
                        state.flowGraphForm.flowGraph = flowGraph;
                    })
                ),

            trackViewerWidth: width =>
                set(
                    produce(state => {
                        state.viewer.width = width;
                    })
                ),

            setDraggingNodeIDs: nodeIDs =>
                set(
                    produce(state => {
                        state.viewer.nodeIDsDragging = nodeIDs;
                    })
                ),

            setPanMode: mode =>
                set(
                    produce(state => {
                        state.viewer.panMode = mode;
                    })
                ),

            togglePanMode: () =>
                set(
                    produce(state => {
                        state.viewer.panMode = !state.viewer.panMode;
                    })
                ),

            setFigmaMode: onOff =>
                set(
                    produce(state => {
                        state.viewer.figmaMode = onOff;
                        localStorage.setItem("figmaMode", onOff ? "true" : "false");
                    })
                ),

            setSelectedNodeIDs: nodeIDs =>
                set(
                    produce(state => {
                        state.viewer.selectedNodeIDs = nodeIDs;
                    })
                ),

            reset: () =>
                set(
                    produce(state => {
                        state = cloneDeep(initStore);
                    })
                ),
        },
    }))
);

export const useViewerStoreMethods = () => useViewerStore(state => state.methods, shallow);
export const useViewerStoreAndMethods = (stateGetter, flags) => [
    useViewerStore(stateGetter, flags),
    useViewerStore(state => state.methods, shallow),
];
export const useViewerStoreVars = (
    ...args // ('var1', 'var2', ...)
) => useViewerStore(state => args.map(prop => state[prop]), shallow);
